import { Modal, Input, Button, Form, Typography } from "antd";
import { useState } from "react";
import "./AddNewSubmetricModal.css";

const { Text } = Typography;

const AddNewSubmetricModal = ({
  isNewSubmetricModal,
  handleOk,
  handleCancel,
  submetricSelectedType,
}) => {
  const [subMetricName, setSubMetricName] = useState("");

  const onSave = () => {
    handleOk(subMetricName);
  };

  return (
    <Modal
      title={
        <Text strong className="modal-title">
          Add New Sub Metric - {submetricSelectedType}
        </Text>
      }
      open={isNewSubmetricModal}
      onCancel={handleCancel}
      centered
      className="submetric-modal"
      footer={[
        <Button key="discard" onClick={handleCancel} className="btn-discard">
          Discard
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={onSave}
          disabled={!subMetricName}
          className={`btn-save ${!subMetricName ? "btn-disabled" : ""}`}
        >
          Save
        </Button>,
      ]}
    >
      <Form layout="vertical" className="submetric-form">
        <Form.Item
          label={<Text className="form-label">Submetric Name</Text>}
          required
          validateStatus={!subMetricName ? "error" : ""}
          help={!subMetricName && "Please enter a submetric name"}
        >
          <Input
            placeholder="Enter submetric name"
            value={subMetricName}
            onChange={(e) => setSubMetricName(e.target.value)}
            className="input-submetric"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewSubmetricModal;
