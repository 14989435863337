import { Button, Input, notification, Table } from "antd";
import React, { useEffect, useState } from "react";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { getApi } from "../../utils/getApiMethod";
import { putApi } from "../../utils/putApiMethod";
import { postApi } from "../../utils/postApiMethod";

const Tickets = () => {
  const [isTicketInfo, setIsTicketInfo] = useState(false);
  const [currentRowRecord, setCurrentRowRecord] = useState(null);
  const [ticketList, setTicketsList] = useState([]);

  const columns = [
    {
      title: "Ticket Id",
      dataIndex: "TICKETID",
      key: "TICKETID",
    },
    {
      title: "Ticket Type",
      dataIndex: "TICKET_TYPE",
      key: "TICKET_TYPE",
    },
    {
      title: "Transaction Number",
      dataIndex: "TRANSACTIONNUM",
      key: "TRANSACTIONNUM",
    },
    {
      title: "Ticket Status",
      dataIndex: "TICKET_STATUS",
      key: "TICKET_STATUS",
    },
    {
      title: "Date Created",
      dataIndex: "DTCR",
      key: "DTCR",
    },
  ];

  const getTicketsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data?.rows) {
        setTicketsList(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "tickets/resource";
    getTicketsList(url);
  }, []);

  const handleBack = () => {
    setIsTicketInfo(false);
  };

  const [commentsList, setCommentsList] = useState([]);

  const getTicketsCommentsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setCommentsList(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRowClick = (record) => {
    setIsTicketInfo(true);
    setCurrentRowRecord(record);
    const url = `viewTIcket?TICKETID=${record?.TICKETID}`;
    getTicketsCommentsList(url);
  };

  const [comment, setComment] = useState("");

  const handleResolve = async () => {
    const payload = {
      COMMENTS: comment,
    };
    const url = `tickets/resource/${currentRowRecord?.id}`;
    try {
      const response = await putApi(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        setComment("");
        const getUrl = `tickets/resource`;
        getTicketsList(getUrl);
        setIsTicketInfo(false);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const handleAddComments = async () => {
    const payload = {
      COMMENTS: comment,
    };
    const updatedPayload = [payload];
    const url = `comments?TICKETID=${currentRowRecord?.TICKETID}`;
    try {
      const response = await postApi(url, updatedPayload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        setComment("");
        const url = `viewTicket?TICKETID=${currentRowRecord?.TICKETID}`;
        getTicketsCommentsList(url);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  return (
    <>
      {isTicketInfo ? (
        <div className="flex overflow-hidden" style={{ height: "100vh" }}>
          <div className="flex-1">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">View Tickets Info</h2>
              <Button onClick={handleBack}>Back</Button>
            </div>

            <div className="flex" style={{ height: "90vh" }}>
              <div className="flex-1 flex flex-col border-r border-gray-200 overflow-hidden">
                <div className="flex-1 overflow-y-auto">
                  <div className="flex mt-2 p-2">
                    <div
                      className={`flex items-center justify-center w-10 h-10 bg-gray-50 rounded-full`}
                    >
                      <div>
                        <BsFillPersonLinesFill className="text-xl" />
                      </div>
                    </div>
                    <div className="ml-2">
                      <p className="com-heading">{`Ticket details of your order`}</p>
                      <p className="agg-value mt-1">{`Ticket Details`}</p>
                    </div>
                  </div>
                  <div className="mt-2 p-2 grid grid-cols-2 gap-3">
                    <div>
                      <p className="agg-value">Ticket ID:</p>
                      <p className="com-heading">
                        {currentRowRecord?.TICKETID}
                      </p>
                    </div>

                    <div>
                      <p className="agg-value">Ticket Type:</p>
                      <p className="com-heading">
                        {currentRowRecord?.TICKET_TYPE}
                      </p>
                    </div>

                    <div>
                      <p className="agg-value">Transaction Number:</p>
                      <p className="com-heading">
                        {currentRowRecord?.TRANSACTIONNUM}
                      </p>
                    </div>

                    <div>
                      <p className="agg-value">Category:</p>
                      <p className="com-heading">
                        {currentRowRecord?.CATEGORY}
                      </p>
                    </div>

                    <div>
                      <p className="agg-value">Date Created:</p>
                      <p className="com-heading">{currentRowRecord?.DTCR}</p>
                    </div>
                  </div>

                  <div className="mt-4 p-2">
                    <Input
                      type="text"
                      className="border p-2 w-full"
                      placeholder="Add your comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <div className="flex mt-2">
                      <Button onClick={handleAddComments}>Add</Button>
                      <Button className="ml-2" onClick={handleResolve}>
                        Resolve
                      </Button>
                    </div>
                  </div>
                  <p className="cus-h2-heading pt-2 px-4 ">{"Comments"}</p>
                  <div className="ml-4 mr-1 bg-gray-100 pl-1 mt-2">
                    <div className="flex w-full p-1">
                      <div className="com-heading w-1/4">{"Time and Date"}</div>
                      <div className="com-heading w-1/4">{"Commentor"}</div>
                      <div className="com-heading w-2/4">{"Comment"}</div>
                    </div>
                  </div>
                  <div className="ml-4 mr-1 mb-4 bg-gray-50 pl-1 overflow-y-auto">
                    {commentsList.map((item) => (
                      <div key={item.id} className="flex w-full p-0.5">
                        <div className="cus-his-value w-1/4">{item.DTCR}</div>
                        <div className="cus-his-value w-1/4">
                          {item.CREATEDBY}
                        </div>
                        <div className="cus-his-value w-2/4">
                          {item.COMMENTS}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="p-4 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h2 className="cus-h2-heading">Tickets List</h2>
          </div>
          <div className="p-1">
            <Table
              dataSource={ticketList}
              columns={columns}
              scroll={{ y: 400 }}
              pagination={{ pageSize: 10 }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Tickets;
