import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { getApi } from "../../../utils/getApiMethod";
import { notification } from "antd";
import { deleteApi } from "../../../utils/deleteApiMethod";
import { FiPlusCircle } from "react-icons/fi";
import AddNewSubmetricModal from "./AddNewSubmetricModal";
import { postApi } from "../../../utils/postApiMethod";
import { putApi } from "../../../utils/putApiMethod";
import { useNavigate } from "react-router-dom";
import "./Inventory.css";
import { HiLightBulb } from "react-icons/hi";

const Inventory = () => {
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState([]);
  const [droppedMetrics, setDroppedMetrics] = useState([]);
  const [configureStarted, setConfigureStarted] = useState(false);
  const submetricSelectedType = "Inventory";

  const getInventorySubMetricLists = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        // setMetrics(result?.data);
        const filteredMetrics = result?.data.filter(
          (metric) => metric.SID === null
        );
        const filteredDroppedMetrics = result?.data.filter(
          (metric) => metric.SID !== null
        );

        const droppedMetricsWithWeightage = filteredDroppedMetrics.map(
          (metric) => {
            const Weightage = (100 / filteredDroppedMetrics.length).toFixed(2);
            return { ...metric, Weightage };
          }
        );
        // console.log("droppedMetricsWithWeightage", droppedMetricsWithWeightage);
        // Log metricname and Weightage
        const metricsArray = droppedMetricsWithWeightage.map((metric) => ({
          metricname: metric.metricname,
          weightage: metric.Weightage,
          metric_type: submetricSelectedType,
          id: metric.metricid,
        }));
        // console.log("Metrics and their Weightage:", metricsArray);
        updateWeightage(metricsArray);

        setMetrics(filteredMetrics);
        setDroppedMetrics(droppedMetricsWithWeightage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = `getAllSubMetric?metric=${submetricSelectedType}`;
    getInventorySubMetricLists(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragStart = (e, metric) => {
    e.dataTransfer.setData("metric", JSON.stringify(metric));
  };

  const onDrop = (e) => {
    const metric = JSON.parse(e.dataTransfer.getData("metric"));
    if (configureStarted) {
      notification.warning({
        message:
          "Please configure the existing metric before adding a new one.",
      });
      return;
    }
    if (metrics.find((item) => item.metricname === metric.metricname)) {
      // setDroppedMetrics([...droppedMetrics, metric]);
      // setMetrics(
      //   metrics.filter((item) => item.metricname !== metric.metricname)
      // );
      // setConfigureStarted(true);

      // with weightage
      const updatedDroppedMetrics = [...droppedMetrics, metric];
      const newWeightage = (100 / updatedDroppedMetrics.length).toFixed(2);
      const droppedMetricsWithWeightage = updatedDroppedMetrics.map((m) => ({
        ...m,
        Weightage: newWeightage,
      }));

      setDroppedMetrics(droppedMetricsWithWeightage);
      setMetrics(
        metrics.filter((item) => item.metricname !== metric.metricname)
      );
      setConfigureStarted(true);
    }
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  const handleCardClick = (metric) => {
    console.log("metric", metric);
    navigate("/app/dashboard/inventoryconfig", {
      state: { metricData: metric, selectedKey: "inventoryconfig" },
    });
  };

  const handleValueClick = (value) => {
    console.log("value", value);
    navigate("/app/warehouse/inventorydetails", {
      state: { selSubMetricData: value, selectedKey: "inventorydetails" },
    });
  };

  const handleRemove = async (metric) => {
    // console.log("remove clicked...", metric.SID);

    // if (metric.SID === null) {
    //   setDroppedMetrics(
    //     droppedMetrics.filter((item) => item.metricname !== metric.metricname)
    //   );
    //   setMetrics([...metrics, metric]);
    //   setConfigureStarted(false);
    //   return;
    // }

    // with weightage
    if (metric.SID === null) {
      const updatedDroppedMetrics = droppedMetrics.filter(
        (item) => item.metricname !== metric.metricname
      );

      const newWeightage =
        updatedDroppedMetrics.length > 0
          ? (100 / updatedDroppedMetrics.length).toFixed(2)
          : 0;
      const droppedMetricsWithWeightage = updatedDroppedMetrics.map((m) => ({
        ...m,
        Weightage: newWeightage,
      }));

      setDroppedMetrics(droppedMetricsWithWeightage);
      setMetrics([...metrics, metric]);
      setConfigureStarted(false);
      return;
    }

    const url = `delete-metric?SID=${metric.SID}`;
    try {
      const response = await deleteApi(url);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
          // description: error.message,
        });
        const url = `getAllSubMetric?metric=${submetricSelectedType}`;
        getInventorySubMetricLists(url);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const updateWeightage = async (metricWeightageList) => {
    // console.log("metricWeightageList", metricWeightageList);
    const url = `update-weightage`;
    try {
      const response = await putApi(url, metricWeightageList);
      if (response?.statusCode === 200) {
        console.log("updated weightage", response.message);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
    }
  };

  const [isNewSubmetricModal, setAddNewSubmetricModal] = useState(false);

  const showModal = () => {
    setAddNewSubmetricModal(true);
  };

  const handleOk = async (subMetricName) => {
    // console.log("Input 1:", subMetricName);
    const payload = {
      metrictype: submetricSelectedType,
      metricname: subMetricName,
    };
    const url = `customize-metric`;
    try {
      const response = await postApi(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        const url = `getAllSubMetric?metric=${submetricSelectedType}`;
        getInventorySubMetricLists(url);
        setAddNewSubmetricModal(false);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const handleCancel = () => {
    setAddNewSubmetricModal(false);
  };

  return (
    <>
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="p-4 flex items-center justify-start border-b border-gray-300 sticky top-0 bg-white z-10">
          <h2 className="cus-h2-heading">
            {submetricSelectedType} Sub Metrics
          </h2>
          <FiPlusCircle
            className="text-xl cursor-pointer ml-2"
            onClick={showModal}
          />
        </div>
        <div className="flex" style={{ height: "90vh" }}>
          <div
            className="w-1/4 flex flex-col overflow-hidden"
            style={{ minHeight: "200px" }}
          >
            <div className="flex-1 p-1 overflow-auto">
              {metrics.map((metric, index) => (
                <div
                  key={index}
                  draggable
                  onDragStart={(e) => onDragStart(e, metric)}
                  className="p-2 m-2 border border-gray-200 rounded-lg cursor-pointer hover:scale-105 hover:bg-blue-50"
                >
                  <div className="com-value text-center">
                    {metric.metricname}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            className="w-3/4 flex flex-col border-l border-gray-300 overflow-auto"
            onDrop={onDrop}
            onDragOver={allowDrop}
            style={{ maxHeight: "100vh" }}
          >
            <div className="flex justify-center">
              <div className="border border-gray-200 mt-4 w-48 h-32 flex flex-col">
                <div className="h-2/6 bg-[#3889e5] flex items-center justify-center">
                  <h3 className="sub-met-heading text-white">
                    {submetricSelectedType}
                  </h3>
                </div>
                <div className="h-4/6 flex items-center justify-center">
                  <p className="sub-met-value">100</p>
                </div>
              </div>
            </div>

            <div className="flex-1 px-3 overflow-auto">
              {droppedMetrics.length === 0 ? (
                <p className="text-gray-500">Drag and drop metrics here</p>
              ) : (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
                  style={{ gridAutoRows: "minmax(150px, auto)" }}
                >
                  {droppedMetrics.map((metric, index) => (
                    <div key={index} className="flex justify-center">
                      <div className="border border-gray-200 my-4 w-48 h-36 flex flex-col">
                        <div className="h-2/6 bg-[#3889e5] relative flex items-center justify-center">
                          <h3
                            className="p-2 sub-met-heading text-white hover:text-[#eff1f2] hover:cursor-pointer flex-grow text-center truncate overflow-hidden whitespace-nowrap"
                            onClick={() => handleCardClick(metric)}
                          >
                            {metric.metricname}
                          </h3>
                          <button
                            className="absolute -top-2 -right-2 bg-white border border-1 border-gray-300 text-red-600 hover:text-red-800 transition-colors p-0.5 rounded-full"
                            onClick={() => handleRemove(metric)}
                          >
                            <IoClose size={12} />
                          </button>
                        </div>

                        <div
                          className={`h-4/6 flex flex-col items-center justify-center`}
                        >
                          <HiLightBulb
                            className={`text-2xl ${
                              metric.status === "green"
                                ? "text-green-600"
                                : metric.status === "yellow"
                                ? "text-yellow-600"
                                : metric.status === "red"
                                ? "text-red-600"
                                : metric.status === "grey"
                                ? "text-gray-600"
                                : "text-gray-600"
                            }`}
                          />
                          <p
                            className="sub-met-value text-center hover:cursor-pointer"
                            onClick={() => handleValueClick(metric)}
                          >
                            <span
                              className={`${
                                metric.status === "green"
                                  ? "text-green-600 hover:text-green-700"
                                  : metric.status === "yellow"
                                  ? "text-yellow-600 hover:text-yellow-700"
                                  : metric.status === "red"
                                  ? "text-red-600 hover:text-red-700"
                                  : metric.status === "grey"
                                  ? "text-gray-600 hover:text-gray-700"
                                  : "text-gray-600 hover:text-gray-700"
                              }`}
                            >
                              {metric.value}
                            </span>
                          </p>
                          <p className="sub-met-value">
                            <span
                              className={`${
                                metric.status === "green"
                                  ? "text-green-600"
                                  : metric.status === "yellow"
                                  ? "text-yellow-600"
                                  : metric.status === "red"
                                  ? "text-red-600"
                                  : metric.status === "grey"
                                  ? "text-gray-600"
                                  : "text-gray-600"
                              }`}
                            >
                              Weightage:{" "}
                            </span>
                            <span
                              className={`${
                                metric.status === "green"
                                  ? "text-green-600"
                                  : metric.status === "yellow"
                                  ? "text-yellow-600"
                                  : metric.status === "red"
                                  ? "text-red-600"
                                  : metric.status === "grey"
                                  ? "text-gray-600"
                                  : "text-gray-600"
                              }`}
                            >
                              {metric.Weightage}%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isNewSubmetricModal && (
        <AddNewSubmetricModal
          isNewSubmetricModal={isNewSubmetricModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          submetricSelectedType={submetricSelectedType}
        />
      )}
    </>
  );
};

export default Inventory;
