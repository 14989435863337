import React from "react";

const Safety = () => {
  return (
    <>
      <div className="flex" style={{ height: "100vh" }}>
        <div className="flex-1 flex flex-col border border-gray-300 m-2 rounded-md overflow-hidden">
          <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h2 className="cus-h2-heading">Safety</h2>
          </div>
          <div className="flex-1 p-3">{"Safety sub metrics list"}</div>
        </div>
      </div>
    </>
  );
};

export default Safety;
