import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/getApiMethod";
import "./InventoryDetails.css";
import { FaClipboardCheck } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import { FiTruck } from "react-icons/fi";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { FaShippingFast } from "react-icons/fa";
import { CloseCircleOutlined } from "@ant-design/icons";

const InventoryDetailpage = ({ num, selectedRow }) => {
  const [inventoryDetailsList, setInventoryDetailsList] = useState([]);
  const [inventoryStatusList, setInventoryStatusList] = useState([]);

  const getInventoryDetailsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setInventoryDetailsList(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = `stock-summary?column=INVID&value=${num}`;
    getInventoryDetailsList(url);
  }, [num]);

  const getInventoryStatusList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setInventoryStatusList(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = `stock-visibility?column=INVID&value=${num}`;
    getInventoryStatusList(url);
  }, [num]);

  const stepIconInventory = {
    "On Putaway": <IoMdCheckmark className="text-xl" />,
    Located: <RiShoppingCart2Line spin className="text-xl" />,
    "Order Located": <FiTruck className="text-xl" />,
    "Order Loaded": <AiOutlineCheckSquare className="text-xl" />,
    "Order Shipped": <FaShippingFast className="text-xl" />,
    default: <CloseCircleOutlined className="text-xl" />,
  };

  const inventoryItem = [
    {
      label: "Inventory Id",
      icon: <FaClipboardCheck className="text-green-500 text-xl" />,
      value: selectedRow?.INVID || "",
      bg: "bg-white",
      iconbg: "bg-green-100",
    },
    {
      label: "SKU Code",
      icon: <FaClipboardCheck className="text-yellow-500 text-xl" />,
      value: selectedRow?.SKUCODE || "",
      bg: "bg-white",
      iconbg: "bg-yellow-100",
    },
  ];

  return (
    <>
      <div className="overflow-y-auto">
        <p className="pb-2 pt-2 pl-4 cus-h2-heading border-b">
          {"Inventory Details"}
        </p>
        <div className="p-1 flex flex-wrap border-b">
          {inventoryItem.map((item, index) => (
            <div className="p-1 pl-3 w-1/3 flex flex-col" key={index}>
              <p>
                <span className="visdtl-cus-heading">{item.label}</span>:{" "}
                <span className="visdtl-cus-value">{item.value}</span>
              </p>
            </div>
          ))}
        </div>

        <div className="pt-2 pl-4 w-full border-b">
          <p className="cus-h2-heading">{"Inventory Tracking"}</p>
          <div className="flex items-center justify-center pt-5 mr-24">
            {inventoryStatusList.map((item, index) => (
              <React.Fragment key={index}>
                <div className="flex flex-col">
                  <div
                    className={`flex items-center justify-center w-10 h-10 rounded-full ${
                      item.completed ? "bg-[#008000]" : "bg-gray-300"
                    } text-white`}
                  >
                    {React.cloneElement(
                      stepIconInventory[item.step] ||
                        stepIconInventory["default"],
                      {
                        style: {
                          color: item.completed ? "white" : "white",
                        },
                      }
                    )}
                  </div>
                </div>

                {index < inventoryStatusList.length - 1 && (
                  <div
                    className={`flex-grow border-t-2 ${
                      item.completed ? "border-[#008000]" : "border-gray-300"
                    } `}
                  ></div>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 mb-2">
            {inventoryStatusList.map((item, index) => (
              <div
                key={index}
                className="mt-1 text-sm"
                style={{ marginLeft: index === 0 ? "0" : `${index * 10}%` }}
              >
                <span className="visdtl-cus-timelineheading block">
                  {item.step || ""}
                </span>
                <span className="visdtl-cus-timelinevalue block">
                  {"Date : "}
                  {item.date || "N/A"}
                </span>
              </div>
            ))}
          </div>
        </div>

        <p className="cus-h2-heading pt-2 px-4">{"History Logs"}</p>
        <div className="ml-4 mr-1 bg-gray-100 pl-1 mt-2">
          <div className="flex w-full p-1">
            <div className="com-heading w-1/4">{"Time and Date"}</div>
            <div className="com-heading w-3/4">{"Actions"}</div>
          </div>
        </div>
        <div className="ml-4 mr-1 bg-gray-50 pl-1 overflow-y-auto">
          {inventoryDetailsList.map((item) => (
            <div key={item.id} className="flex w-full p-0.5">
              <div className="cus-his-value w-1/4">{item.DTCR}</div>
              <div className="cus-his-value w-3/4">{item.REMARK}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InventoryDetailpage;
