import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Input, Select, Switch } from "antd";
import { IoSearch } from "react-icons/io5";
import { HiOutlineRefresh } from "react-icons/hi";

const MultiFieldFormComponent = ({
  formFields,
  handleSubmit,
  control,
  errors,
  onSubmit,
  handleClear,
}) => {
  const [operator, setOperator] = useState("");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4">
        <div className="w-full md:w-5/6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {formFields.map(
            ({
              name,
              label,
              type,
              icon: Icon,
              options,
              multiple,
              conditional,
            }) => {
              if (conditional && conditional !== operator) {
                return null;
              }

              return (
                <div key={name} className="flex flex-col space-y-2">
                  <label className="com-heading flex items-center space-x-2">
                    {Icon && <Icon className="text-xl" />}
                    <span>{label}</span>
                  </label>
                  <Controller
                    name={name}
                    control={control}
                    render={({ field }) => {
                      if (type === "input") {
                        return (
                          <Input
                            {...field}
                            size="large"
                            className="border-gray-300 rounded-md"
                          />
                        );
                      } else if (type === "select") {
                        return (
                          <Select
                            {...field}
                            size="large"
                            className="border-gray-300 rounded-md"
                            mode={multiple ? "multiple" : null}
                            value={
                              field.value !== undefined
                                ? field.value
                                : multiple
                                ? []
                                : ""
                            }
                            onChange={(value) => {
                              field.onChange(value);
                              if (name === "operator") {
                                setOperator(value); // Update operator value
                              }
                            }}
                          >
                            {options.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                <div className="flex items-center space-x-2">
                                  <option.icon
                                    style={{ color: option.color }}
                                  />
                                  <span>{option.label}</span>
                                </div>
                              </Select.Option>
                            ))}
                          </Select>
                        );
                      } else if (type === "switch") {
                        return (
                          <Switch
                            {...field}
                            style={{ width: "30px" }}
                            className="border-gray-300 rounded-xl"
                          />
                        );
                      } else if (type === "para") {
                        return (
                          <div className="flex items-center space-x-2">
                            <span className="cus-content">{name}</span>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }}
                  />
                  {errors[name] && (
                    <p className="text-red-500 text-sm">
                      {errors[name].message}
                    </p>
                  )}
                </div>
              );
            }
          )}
        </div>
        <div className="w-1/6 flex items-center justify-end">
          <IoSearch
            className="ml-2 mt-7 p-3 w-10 h-10 flex bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 active:bg-gray-300 transition duration-200 ease-in-out transform active:scale-95"
            onClick={handleSubmit(onSubmit)}
          />
          <HiOutlineRefresh
            className="ml-3 mt-7 p-3 w-10 h-10 flex bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 active:bg-gray-300 transition duration-200 ease-in-out transform active:scale-95"
            onClick={handleClear}
          />
        </div>
      </div>
    </form>
  );
};

export default MultiFieldFormComponent;
