import React from "react";
import { Controller } from "react-hook-form";
import { Input, Select, Button, Switch } from "antd";

const TicketsFormComponent = ({
  formFields,
  handleSubmit,
  control,
  errors,
  onSubmit,
  buttonName,
  buttonLoading,
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mb-4">
      {formFields.map(
        ({
          name,
          label,
          type,
          icon: Icon,
          options,
          multiple,
          defaultValue,
          disabled,
        }) => {
          return (
            <div key={name} className="flex flex-col space-y-2">
              {type === "heading" ? (
                <h3 className="cus-h2-heading">{label}</h3>
              ) : (
                <>
                  <label className="com-heading flex items-center space-x-2">
                    {Icon && <Icon className="text-xl" />}
                    <span>{label}</span>
                  </label>
                  <Controller
                    name={name}
                    control={control}
                    render={({ field }) => {
                      if (defaultValue) {
                        field.value = defaultValue;
                      }

                      if (type === "input") {
                        return (
                          <Input
                            {...field}
                            size="large"
                            className="border-gray-300 rounded-md"
                            disabled={disabled}
                          />
                        );
                      } else if (type === "select") {
                        return (
                          <Select
                            {...field}
                            size="large"
                            className="border-gray-300 rounded-md"
                            mode={multiple ? "multiple" : null}
                            value={
                              field.value !== undefined
                                ? field.value
                                : multiple
                                ? []
                                : ""
                            }
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          >
                            {options.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                <div className="flex items-center space-x-2">
                                  <option.icon
                                    style={{ color: option.color }}
                                  />
                                  <span>{option.label}</span>
                                </div>
                              </Select.Option>
                            ))}
                          </Select>
                        );
                      } else if (type === "switch") {
                        return (
                          <Switch
                            {...field}
                            style={{ width: "30px" }}
                            className="border-gray-300 rounded-xl"
                          />
                        );
                      } else {
                        return null;
                      }
                    }}
                  />
                  {errors[name] && (
                    <p className="text-red-500 text-sm">
                      {errors[name].message}
                    </p>
                  )}
                </>
              )}
            </div>
          );
        }
      )}
      <div className="mt-4">
        <Button
          type="primary"
          htmlType="submit"
          className="w-full cus-btn-full-wid rounded-md shadow"
          loading={buttonLoading}
        >
          <span className="ml-2">{buttonName}</span>
        </Button>
      </div>
    </form>
  );
};

export default TicketsFormComponent;
