import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FaSignal } from "react-icons/fa";
import { BsExclamationCircle } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { getApi } from "../../../utils/getApiMethod";
import { postApi } from "../../../utils/postApiMethod";
import { deleteApi } from "../../../utils/deleteApiMethod";
import FormComponent from "../../../components/Formcomponent";

const AlertSchema = yup.object().shape({
  operator: yup.string().required("Operator is required"),
  severity: yup.string().required("Severity is required"),
});

const SeverityConfig = ({
  selSubMetric,
  setNextButtonEnable,
  setNonLowSeverities,
  selectedAlarm,
}) => {
  // console.log("selSubMetric", selSubMetric);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AlertSchema),
    defaultValues: {
      operator: "",
      severity: "",
    },
  });

  //   const [contactList, setContactsList] = useState([]);
  const [alertListRes, setAlertListRes] = useState([]);

  const getAlertLists = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setAlertListRes(result?.data?.rows);
        const filteredSeverities = result?.data?.rows
          .filter((ele) => ele.severity.toLowerCase() !== "low")
          .map((ele) => ele.severity);
        // console.log("filteredSeverities", filteredSeverities);

        setNonLowSeverities(filteredSeverities);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("selSubMetric", selSubMetric);
    const url = `alertconfig/resource?column=metricid&value=${
      selSubMetric?.metricid || 0
    }`;
    getAlertLists(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    // console.log("data", data);
    // console.log("selSubMetric", selSubMetric);

    const undefinedValues = {};
    const modifiedData = {
      ...data,
      metricid: selSubMetric.metricid,
      submetricname: selSubMetric.metricname || "",
    };
    Object.keys(modifiedData).forEach((key) => {
      if (modifiedData[key] === undefined) {
        undefinedValues[key] = modifiedData[key];
        delete modifiedData[key];
      }
    });
    // console.log("modifiedData", modifiedData);
    // console.log("undefinedValues", undefinedValues);

    // Check for duplicates severity
    const severityCounts = alertListRes.reduce(
      (acc, ele) => {
        const severity = ele.severity.toLowerCase();
        if (severity === "low") acc.low += 1;
        if (severity === "medium") acc.medium += 1;
        if (severity === "high") acc.high += 1;
        return acc;
      },
      { low: 0, medium: 0, high: 0 }
    );

    if (modifiedData.severity) {
      const lowerSeverity = modifiedData.severity.toLowerCase();

      if (lowerSeverity === "low" && severityCounts.low >= 1) {
        notification.warning({
          message: "Warning",
          description: "You can only have one instance of Low Severity.",
        });
        return;
      }

      if (lowerSeverity === "medium" && severityCounts.medium >= 1) {
        notification.warning({
          message: "Warning",
          description: "You can only have one instance of Medium Severity.",
        });
        return;
      }

      if (lowerSeverity === "high" && severityCounts.high >= 1) {
        notification.warning({
          message: "Warning",
          description: "You can only have one instance of High Severity.",
        });
        return;
      }
    }

    const url = `alertconfig/resource`;
    try {
      const response = await postApi(url, modifiedData);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        reset();
        data.weightage = "";
        const url = `alertconfig/resource?column=metricid&value=${
          selSubMetric?.metricid || 0
        }`;
        getAlertLists(url);
        // setNextButtonEnable(false);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
      setNextButtonEnable(true);
    }
  };

  const handleRemoveAlarm = async (ele) => {
    // console.log("remove clicked...", ele);
    setNextButtonEnable(false);
    const url = `alertconfig/resource/${ele.id}`;
    try {
      const response = await deleteApi(url);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
          // description: error.message,
        });
        const url = `alertconfig/resource?column=metricid&value=${
          selSubMetric?.metricid || 0
        }`;
        getAlertLists(url);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const formFields = [
    {
      name: "operator",
      label: "Operator",
      type: "select",
      icon: FaSignal,
      options: [
        {
          value: "less_than",
          label: "Less Than",
          icon: FaSignal,
          color: "blue",
        },
        {
          value: "equal_to",
          label: "Equal To",
          icon: FaSignal,
          color: "green",
        },
        {
          value: "greater_than",
          label: "Greater Than",
          icon: FaSignal,
          color: "red",
        },
        { value: "between", label: "Between", icon: FaSignal, color: "orange" },
      ],
    },
    {
      name: "range",
      label: "Range",
      type: "input",
      conditional: "greater_than",
    },
    {
      name: "range",
      label: "Range",
      type: "input",
      conditional: "equal_to",
    },
    {
      name: "range",
      label: "Range",
      type: "input",
      conditional: "less_than",
    },
    {
      name: "startValue",
      label: "Start Value",
      type: "input",
      conditional: "between",
    },
    {
      name: "endValue",
      label: "End Value",
      type: "input",
      conditional: "between",
    },
    {
      name: "severity",
      label: "Severity",
      type: "select",
      icon: BsExclamationCircle,
      options: [
        {
          value: "high",
          label: "High",
          icon: BsExclamationCircle,
          color: "red",
        },
        {
          value: "medium",
          label: "Medium",
          icon: BsExclamationCircle,
          color: "yellow",
        },
        {
          value: "low",
          label: "Low",
          icon: BsExclamationCircle,
          color: "green",
        },
      ],
    },
  ];

  const countSeverities = alertListRes.reduce(
    (acc, ele) => {
      const severity = ele.severity.toLowerCase();
      if (severity === "low") {
        acc.low += 1;
      } else if (severity === "medium") {
        acc.medium += 1;
      } else if (severity === "high") {
        acc.high += 1;
      }
      return acc;
    },
    { low:0, medium: 0, high: 0 }
  );

  const hasNonLowSeverity =
    countSeverities.low === 1 && countSeverities.medium === 1 && countSeverities.high === 1;

  if (hasNonLowSeverity) {
    setNextButtonEnable(false);
  } else {
    setNextButtonEnable(true);
  }

  return (
    <>
      <div className="flex space-x-2" style={{ height: "65vh" }}>
        <div className="flex-1 flex flex-col bg-gray-50 overflow-hidden">
          <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h2 className="cus-h2-heading">
              {`Configure Severity for a ${selSubMetric.metricname}`}
            </h2>
          </div>
          <div
            className="flex-1 space-x-6 overflow-y-auto"
            style={{ height: "100vh" }}
          >
            <div className="p-3">
              <FormComponent
                formFields={formFields}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                buttonName={"Submit"}
              />
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col bg-gray-50 overflow-hidden border-l">
          <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h2 className="cus-h2-heading">Created Severity</h2>
          </div>
          <div className="m-2" style={{ overflowY: "auto" }}>
            {alertListRes.length > 0 ? (
              <>
                {/* Low Severity Severity */}
                {alertListRes.some(
                  (ele) => ele.severity.toLowerCase() === "low"
                ) && (
                  <>
                    <h3 className="alarm-low-heading">Low Severity</h3>
                    <ul>
                      {alertListRes
                        .filter((ele) => ele.severity.toLowerCase() === "low")
                        .map((ele, index) => (
                          <li
                            key={index}
                            className={`p-4 mb-4 mx-2 mt-2 ${
                              selectedAlarm === ele.id
                                ? "bg-blue-200"
                                : "bg-white"
                            } rounded-lg shadow-sm border border-gray-200`}
                          >
                            <div className="mb-2 flex justify-between items-center">
                              <div>
                                <span
                                  className="com-heading"
                                  style={{ color: "#1E90FF" }}
                                >
                                  Title:
                                </span>{" "}
                                <span className="com-value">
                                  {ele.submetricname}
                                </span>
                              </div>
                              <button
                                className="text-red-600 hover:text-red-800 transition-colors p-1 rounded-full hover:bg-red-100"
                                onClick={() => handleRemoveAlarm(ele)}
                              >
                                <IoClose size={22} />
                              </button>
                            </div>

                            <div className="mb-2">
                              <span
                                className="com-heading"
                                style={{ color: "#10b935" }}
                              >
                                Message:
                              </span>{" "}
                              <span className="com-value">
                                {ele.submetricname +
                                  " for " +
                                  ele.operator +
                                  " " +
                                  ele.range}
                              </span>
                            </div>
                            <div>
                              <span
                                className="com-heading"
                                style={{ color: "#A855F7" }}
                              >
                                Severity:
                              </span>{" "}
                              <span className="com-value">{ele.severity}</span>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </>
                )}

                {/* Non-Low Severity Severity */}
                {alertListRes.some(
                  (ele) => ele.severity.toLowerCase() !== "low"
                ) && (
                  <>
                    <h3 className="alarm-oth-heading">
                      High & Medium Severity
                    </h3>
                    <ul>
                      {alertListRes
                        .filter((ele) => ele.severity.toLowerCase() !== "low")
                        .map((ele, index) => (
                          <li
                            key={index}
                            className={`p-4 mb-4 mx-2 mt-2 ${
                              selectedAlarm === ele.id
                                ? "bg-blue-200"
                                : "bg-white"
                            } rounded-lg shadow-sm border border-gray-200`}
                          >
                            <div className="mb-2 flex justify-between items-center">
                              <div>
                                <span
                                  className="com-heading"
                                  style={{ color: "#1E90FF" }}
                                >
                                  Title:
                                </span>{" "}
                                <span className="com-value">
                                  {ele.submetricname}
                                </span>
                              </div>
                              <button
                                className="text-red-600 hover:text-red-800 transition-colors p-1 rounded-full hover:bg-red-100"
                                onClick={() => handleRemoveAlarm(ele)}
                              >
                                <IoClose size={22} />
                              </button>
                            </div>

                            <div className="mb-2">
                              <span
                                className="com-heading"
                                style={{ color: "#10b935" }}
                              >
                                Message:
                              </span>{" "}
                              <span className="com-value">
                                {ele.submetricname +
                                  " for " +
                                  ele.operator +
                                  " " +
                                  ele.range}
                              </span>
                            </div>
                            <div>
                              <span
                                className="com-heading"
                                style={{ color: "#A855F7" }}
                              >
                                Severity:
                              </span>{" "}
                              <span className="com-value">{ele.severity}</span>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </>
            ) : (
              <p className="p-2 m-2 border border-gray-200 rounded-lg bg-gradient-to-r from-blue-50 to-white shadow-md cursor-pointer transition-transform hover:bg-blue-50">
                No Severity created yet.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SeverityConfig;
