import React, { useEffect, useState } from "react";
import { Avatar, Layout, Menu } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import compLogo from "../src/assests/linkworks_logo.png";
import { MdDashboard, MdInventory2 } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import "./landing.css";
import { IoPersonAddSharp } from "react-icons/io5";
import { FaBolt, FaRobot } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { useAuth } from "./AuthContext";

const { Content, Sider } = Layout;

const items = [
  {
    key: "home",
    icon: <AiFillHome />,
    label: "Home",
  },
  {
    key: "dashboard",
    icon: <MdDashboard />,
    label: "Dashboard",
  },
  {
    key: "contact",
    icon: <IoPersonAddSharp />,
    label: "Contact",
  },
  {
    key: "warehouse",
    icon: <MdInventory2 />,
    label: "Warehouse",
    children: [
      {
        key: "warehouse/visibility",
        label: "Visibility",
      },
      {
        key: "warehouse/inventorydetails",
        label: "Inventory Details",
      },
      {
        key: "warehouse/costdetails",
        label: "Cost Details",
      },
    ],
  },
  {
    key: "incident",
    icon: <FaBolt />,
    label: "Incident",
    children: [
      {
        key: "incident/escalatedincidents",
        label: "Escalated Incidents",
      },
      {
        key: "incident/tickets",
        label: "Tickets",
      },
    ],
  },
  {
    key: "LWBot",
    icon: <FaRobot />,
    label: "LWBot",
  },
];

const Landing = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const location = useLocation();

  const [selectedKey, setSelectedKey] = useState(
    localStorage.getItem("selectedKey") || "home"
  );

  useEffect(() => {
    // Check if there is a selectedKey passed in the state
    if (location.state && location.state.selectedKey) {
      setSelectedKey(location.state.selectedKey);
    }

    localStorage.setItem("selectedKey", selectedKey);
  }, [selectedKey, location.state]);

  const handleMenuClick = ({ key }) => {
    navigate(`/app/${key}`);
    setSelectedKey(key);
  };

  const handleLogout = () => {
    console.log("Logout clicked");
    logout();
    navigate("/");
  };

  const user = {
    name: "Admin",
    initials: "A",
  };

  return (
    <Layout>
      {selectedKey !== "inventoryconfig" && (
        <>
          <Sider className="siderbar" breakpoint="lg" collapsedWidth="80">
            <div className="h-screen siderbar">
              <div className="flex-1 bg-white flex flex-col h-full">
                <div className="flex flex-col flex-1">
                  <div className="flex-grow">
                    <div className="top-cus-logo-section">
                      <img
                        className="cus-logo"
                        src={compLogo}
                        alt="Profile Logo"
                      />
                    </div>
                    <div>
                      <Menu
                        className="menu-scroll-container"
                        mode={"inline"}
                        items={items}
                        onClick={handleMenuClick}
                        selectedKeys={[selectedKey]}
                      />
                    </div>
                  </div>
                  {/* Bottom Button Group */}
                  <div className="p-3 flex justify-between items-center border-t">
                    <Avatar className="mr-4 bg-[#247fd3]" size="small">
                      <span
                        className="cus-prof-name"
                        style={{ color: "white" }}
                      >
                        {user.initials}
                      </span>
                    </Avatar>
                    <div className="flex-grow cus-prof-name">{user.name}</div>
                    <FiLogOut className="ml-4 text-xl" onClick={handleLogout} />
                  </div>
                </div>
              </div>
            </div>
          </Sider>
        </>
      )}

      <Layout>
        <Content className="content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Landing;
