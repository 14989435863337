import React, { useEffect, useState } from "react";
import { Carousel, Table } from "antd";
import { HiLightBulb } from "react-icons/hi";
import { FaCheckCircle, FaTasks, FaTimesCircle } from "react-icons/fa";
import "./Homepage.css";
import { getApi } from "../../utils/getApiMethod";

const HomePage = () => {
  const carouselSettings = {
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  const [quickSummary, setQuickSummary] = useState([]);
  // const [quickSummaryloading, setQuickSummaryLoading] = useState(true);

  const getQuickSummary = async (url) => {
    // setQuickSummaryLoading(true);
    try {
      const result = await getApi(url);
      if (result?.data?.summary) {
        setQuickSummary(result?.data?.summary);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // finally {
    //   setQuickSummaryLoading(false);
    // }
  };

  useEffect(() => {
    const url = "get_summary";
    getQuickSummary(url);
  }, []);

  const [todaySummary, setTodaySummary] = useState([]);
  // const [todaySummaryloading, setTodaySummaryLoading] = useState(true);

  const getTodaySummary = async (url) => {
    // setTodaySummaryLoading(true);
    try {
      const result = await getApi(url);
      if (result?.data?.summary) {
        setTodaySummary(result?.data?.summary);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // finally {
    //   setTodaySummaryLoading(false);
    // }
  };

  useEffect(() => {
    const url = "get_summary_today";
    getTodaySummary(url);
  }, []);

  const columns = [
    {
      title: "Escalation Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Submetric Name",
      dataIndex: "submetric",
      key: "submetric",
    },
    {
      title: "Level Of Escalation",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Assignee",
      dataIndex: "assignto",
      key: "assignto",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        let color;
        if (text === "High") {
          color = "#FF0000";
        } else if (text === "Medium") {
          color = "#FFA500";
        } else {
          color = "#FFFF00";
        }
        return (
          <span style={{ color }}>
            <HiLightBulb className="text-2xl" />
          </span>
        );
      },
    },
  ];

  const [escalationList, setEscalationList] = useState([]);

  const getEscalationList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data?.rows) {
        setEscalationList(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url =
      "dynamic-search?PrimaryTableName=TV_ESCAL00&attributes=date,submetric,assignto,level,status";
    getEscalationList(url);
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <div className="flex p-2" style={{ height: "50vh" }}>
        <div className="flex-1 flex flex-col border border-gray-100 mr-1 bg-white rounded-lg shadow-md overflow-hidden">
          <p className="cus-h2-heading flex items-center justify-center border-b p-2">
            <FaTasks className="mr-2 text-blue-500" />
            {"Quick Summary"}
          </p>

          {quickSummary && quickSummary.length > 0 ? (
            <Carousel {...carouselSettings}>
              {quickSummary.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center text-center h-[39vh] p-4"
                >
                  <p className="cus-h2-heading flex items-center justify-center">
                    {item.message}
                  </p>

                  {/* Optional status display */}
                  <div
                    className={`home-status inline-flex rounded-lg mt-4 p-2 ${
                      item.status === "good"
                        ? "text-green-500 bg-green-100"
                        : item.status === "moderate"
                        ? "text-yellow-500 bg-yellow-100"
                        : "text-red-500 bg-red-100"
                    } flex items-center justify-center`}
                  >
                    {item.status === "good" ? (
                      <FaCheckCircle className="mr-2" />
                    ) : item.status === "moderate" ? (
                      <FaCheckCircle className="mr-2" />
                    ) : (
                      <FaTimesCircle className="mr-2" />
                    )}
                    {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                  </div>
                </div>
              ))}
            </Carousel>
          ) : (
            <div className="flex items-center justify-center h-[39vh] p-4 text-gray-500">
              No data available
            </div>
          )}
        </div>

        <div className="flex-1 flex flex-col border border-gray-100 mr-1 bg-white rounded-lg shadow-md overflow-hidden">
          <p className="cus-h2-heading flex items-center justify-center border-b p-2">
            <FaTasks className="mr-2 text-blue-500" />
            {"Summary PlanData"}
          </p>

          {todaySummary && todaySummary.length > 0 ? (
            <Carousel {...carouselSettings}>
              {todaySummary.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center text-center h-[39vh] p-4"
                >
                  <p className="cus-h2-heading flex items-center justify-center">
                    {item.message}
                  </p>

                  {/* Optional status display */}
                  <div
                    className={`home-status inline-flex rounded-lg mt-4 p-2 ${
                      item.status === "good"
                        ? "text-green-500 bg-green-100"
                        : item.status === "moderate"
                        ? "text-yellow-500 bg-yellow-100"
                        : "text-red-500 bg-red-100"
                    } flex items-center justify-center`}
                  >
                    {item.status === "good" ? (
                      <FaCheckCircle className="mr-2" />
                    ) : item.status === "moderate" ? (
                      <FaCheckCircle className="mr-2" />
                    ) : (
                      <FaTimesCircle className="mr-2" />
                    )}
                    {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                  </div>
                </div>
              ))}
            </Carousel>
          ) : (
            <div className="flex items-center justify-center h-[39vh] p-4 text-gray-500">
              No data available
            </div>
          )}
        </div>
      </div>
      <div className="flex p-2" style={{ height: "50vh" }}>
        <div className="w-full">
          <h3 className="cus-home-title ml-2">Escalated Incidents</h3>
          <Table
            className="mt-2"
            columns={columns}
            dataSource={escalationList}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
