import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiOutlineIdcard } from "react-icons/ai";
import { TbFileInvoice } from "react-icons/tb";
import { Button, Table } from "antd";
import "./InventoryDetails.css";
import MultiFieldFormComponent from "../../components/MultiFieldFormComponents";
import { getApi } from "../../utils/getApiMethod";
import InventoryDetailpage from "./InventoryDetailpage";
import { useLocation } from "react-router-dom";
import { Form, Select, Input, Row, Col } from "antd";
import { postApi } from "../../utils/postApiMethod";

const schema = yup.object().shape({});

const { Option } = Select;

const TABLES = {
  STKDETL01: [
    "COMPANY",
    "WHSE",
    "INVID",
    "INVLINENUM",
    "INOWNER",
    "SKUCODE",
    "BATCH",
    "INTBATCH",
    "MFGDATE",
    "EXPDATE",
    "WRNTDATE",
    "MRP",
    "PACKID",
    "CASEID",
    "LDUNIT",
    "PALLETID",
    "PRVPALLETID",
    "IBHUID",
    "OBHUID",
    "RCPTNUM",
    "RCPTLINENUM",
    "RCPTDATE",
    "RCVDDTTM",
    "VENDOR",
  ],
};

const InventoryDetails = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      INVID: "",
      SKUCODE: "",
    },
  });
  const location = useLocation();
  const selSubMetricData = location.state?.selSubMetricData;

  const [inventoryDetailRes, setInventoryDetailRes] = useState([]);
  const [defaultqueryRes, setDefaultqueryRes] = useState({});
  const [queryInventoryDetailRes, setqueryInventoryDetailRes] = useState([]);
  const [generateQuery, setGenerateQuery] = useState(false);

  const getInventoryDetailLists = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        // console.log("result", result?.data);
        // console.log("defaultQuery", result?.defaultQuery);
        setInventoryDetailRes(result?.data);
        setDefaultqueryRes(result?.defaultQuery);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = `view-query?metricid=${selSubMetricData?.metricid || 0}`;
    getInventoryDetailLists(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log("selSubMetricData", selSubMetricData);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const [selectedRow, setSelectedRow] = useState(null);

  const onSubmit = async (data) => {
    setGenerateQuery(false);
    const { columns, values } = Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc.columns.push(key);
          acc.values.push(value);
        }
        return acc;
      },
      { columns: [], values: [] }
    );

    const url = `view-query?metricid=${selSubMetricData?.metricid || 0}${
      columns.length > 0
        ? `&column=${columns.join(",")}&value=${values.join(",")}`
        : ""
    }`;

    // console.log("Constructed URL:", url);

    getInventoryDetailLists(url);
  };

  const formFields = [
    {
      name: "INVID",
      label: "Inventory Id",
      type: "input",
      icon: AiOutlineIdcard,
    },
    {
      name: "SKUCODE",
      label: "SKU Code",
      type: "input",
      icon: TbFileInvoice,
    },
  ];

  const tableColumns = [
    {
      title: "Inventory Id",
      dataIndex: "INVID",
      key: "INVID",
    },
    {
      title: "SKU Code",
      dataIndex: "SKUCODE",
      key: "SKUCODE",
    },
  ];

  const handleRowClick = (record) => {
    setSelectedRow(record);
  };

  const handleBackClick = () => {
    setSelectedRow(null);
    reset();
    setInventoryDetailRes([]);

    const url = `view-query?metricid=${selSubMetricData?.metricid || 0}`;
    getInventoryDetailLists(url);
  };

  const handleClear = () => {
    setGenerateQuery(false);
    reset();
    const url = `view-query?metricid=${selSubMetricData?.metricid || 0}`;
    getInventoryDetailLists(url);
  };

  const num = selectedRow?.INVID;

  //Nocode - form
  const [selectedTable, setSelectedTable] = useState("");
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filters, setFilters] = useState([]);

  // Default query to populate the form
  // const defaultQuery = {
  //   table: "STKDETL01",
  //   columns: ["*"],
  //   filters: [
  //     {
  //       column: "DTCR",
  //       operator: "=",
  //       value: "2024-11-07",
  //       logicalOperator: "AND",
  //     },
  //     {
  //       column: "DTCR",
  //       operator: "<",
  //       value: "2024-08-07",
  //       logicalOperator: "AND",
  //     },
  //   ],
  // };

  // // Set default values when the component mounts
  // useEffect(() => {
  //   setSelectedTable(defaultQuery.table);
  //   setSelectedColumns(defaultQuery.columns);
  //   setFilters(defaultQuery.filters);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (
      defaultqueryRes &&
      defaultqueryRes.table &&
      defaultqueryRes.columns &&
      defaultqueryRes.filters
    ) {
      setSelectedTable(defaultqueryRes.table);
      setSelectedColumns(defaultqueryRes.columns);
      setFilters(defaultqueryRes.filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultqueryRes]);

  const handleTableChange = (value) => {
    setSelectedTable(value);
    setSelectedColumns([]);
  };

  const handleFilterChange = (index, key, value) => {
    const newFilters = [...filters];
    newFilters[index][key] = value;
    setFilters(newFilters);
  };

  const addFilter = () => {
    setFilters([
      ...filters,
      { column: "", operator: "", value: "", logicalOperator: "AND" },
    ]);
  };

  const handleQuerySubmit = async () => {
    setGenerateQuery(true);
    if (!selectedTable) {
      console.error("Table must be selected.");
      return;
    }

    // Default to '*' if no specific columns are selected
    const columnsPart =
      selectedColumns.length > 0
        ? selectedColumns.map((col) => `"${col}"`).join(", ")
        : "*";
    let query = `SELECT ${columnsPart} FROM public."${selectedTable}"`;

    // Filter data: Create WHERE clause
    const whereClauses = filters
      .filter((filter) => filter.column && filter.operator && filter.value)
      .map((filter, index) => {
        const clause = `"${filter.column}" ${filter.operator} '${filter.value}'`;
        return index > 0 ? `${filter.logicalOperator} ${clause}` : clause;
      });

    if (whereClauses.length > 0) {
      query += ` WHERE ${whereClauses.join(" ")}`;
    }

    // console.log("Generated Query:", query);
    const payload = {
      query: query,
    };

    const url = `view-dataset?metricid=${selSubMetricData?.metricid || 0}`;
    // console.log("Final URL:", url);
    try {
      const result = await postApi(url, payload);
      if (result?.data) {
        // console.log("result", result?.data);
        setqueryInventoryDetailRes(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div className="flex" style={{ height: "100vh" }}>
        <div className="flex-1 flex flex-col overflow-hidden">
          {!selectedRow && (
            <div className="p-4 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Inventory</h2>
            </div>
          )}
          {selectedRow && (
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Inventory</h2>
              <Button onClick={handleBackClick}>Back</Button>
            </div>
          )}

          {!selectedRow && (
            <div className="overflow-y-auto">
              <div className="p-3">
                <MultiFieldFormComponent
                  formFields={formFields}
                  handleSubmit={handleSubmit}
                  control={control}
                  errors={errors}
                  onSubmit={onSubmit}
                  buttonName={"Submit"}
                  handleClear={handleClear}
                />
              </div>

              <div style={{ padding: "20px" }}>
                <Form layout="vertical" onFinish={handleQuerySubmit}>
                  {/* Select a Table */}
                  <Form.Item label="Select a Table" required>
                    <Select
                      placeholder="Choose a table"
                      onChange={handleTableChange}
                      value={selectedTable}
                    >
                      {Object.keys(TABLES).map((table) => (
                        <Option key={table} value={table}>
                          {table}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {/* Select Required Columns */}
                  <Form.Item label="Select Required Columns (Optional)">
                    <Select
                      mode="multiple"
                      placeholder="Select columns"
                      onChange={setSelectedColumns}
                      value={selectedColumns}
                      disabled={!selectedTable}
                    >
                      {selectedTable &&
                        TABLES[selectedTable].map((column) => (
                          <Option key={column} value={column}>
                            {column}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  {/* Filter Data to Monitor */}
                  <Form.Item label="Filter Data to Monitor (Optional)">
                    {filters.map((filter, index) => (
                      <Row
                        gutter={8}
                        key={index}
                        style={{ marginBottom: "10px" }}
                      >
                        <Col span={6}>
                          <Select
                            placeholder="Select column"
                            value={filter.column}
                            onChange={(value) =>
                              handleFilterChange(index, "column", value)
                            }
                            disabled={!selectedTable}
                          >
                            {selectedTable &&
                              TABLES[selectedTable].map((column) => (
                                <Option key={column} value={column}>
                                  {column}
                                </Option>
                              ))}
                          </Select>
                        </Col>
                        <Col span={4}>
                          <Select
                            placeholder="Operator"
                            value={filter.operator}
                            onChange={(value) =>
                              handleFilterChange(index, "operator", value)
                            }
                          >
                            <Option value="=">=</Option>
                            <Option value=">">{">"}</Option>
                            <Option value="<">{"<"}</Option>
                            <Option value=">=">{">="}</Option>
                            <Option value="<=">{"<="}</Option>
                            <Option value="!=">!=</Option>
                          </Select>
                        </Col>
                        <Col span={8}>
                          <Input
                            placeholder="Value"
                            value={filter.value}
                            onChange={(e) =>
                              handleFilterChange(index, "value", e.target.value)
                            }
                          />
                        </Col>
                        <Col span={4}>
                          {index > 0 && (
                            <Select
                              placeholder="Logical Op"
                              value={filter.logicalOperator}
                              onChange={(value) =>
                                handleFilterChange(
                                  index,
                                  "logicalOperator",
                                  value
                                )
                              }
                            >
                              <Option value="AND">AND</Option>
                              <Option value="OR">OR</Option>
                            </Select>
                          )}
                        </Col>
                      </Row>
                    ))}
                    <Button type="dashed" onClick={addFilter}>
                      Add Condition
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Generate Query
                    </Button>
                  </Form.Item>
                </Form>
              </div>

              <div className="mx-1">
                <Table
                  dataSource={
                    generateQuery ? queryInventoryDetailRes : inventoryDetailRes
                  }
                  columns={tableColumns}
                  scroll={{ y: 300 }}
                  pagination={{ pageSize: 10 }}
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                  })}
                />
              </div>
            </div>
          )}
          {selectedRow && (
            <InventoryDetailpage
              handleBackClick={handleBackClick}
              num={num}
              selectedRow={selectedRow}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default InventoryDetails;
