import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./app/Login/Login";
import Landing from "./landing";
import HomePage from "./app/Home/Homepage";
import Dashboard from "./app/Dashboard/Dashboard";
import Contact from "./app/Contact/Contact";
import Visibility from "./app/Visibility/Visibility";
import InventoryDetails from "./app/Inventory/InventoryDetails";
import EscalatedIncident from "./app/EscalatedIncident/EscalatedIncident";
import Tickets from "./app/Tickets/Tickets";
import Cost from "./app/SubMetrics/Cost/Cost";
import Inventory from "./app/SubMetrics/Inventory/Inventory";
import Safety from "./app/SubMetrics/Safety/Safety";
import { AuthProvider, useAuth } from "./AuthContext";
import InventoryConfig from "./app/SubMetrics/InventoryConfig/InventoryConfig";
import LWBot from "./app/LWBot/LWBot";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? children : <Navigate to="/" />;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/app" element={<ProtectedRoute><Landing /></ProtectedRoute>}>
            <Route path="home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
            <Route path="dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="contact" element={<ProtectedRoute><Contact /></ProtectedRoute>} />
            <Route path="warehouse/visibility" element={<ProtectedRoute><Visibility /></ProtectedRoute>} />
            <Route path="warehouse/inventorydetails" element={<ProtectedRoute><InventoryDetails /></ProtectedRoute>} />
            <Route path="incident/escalatedincidents" element={<ProtectedRoute><EscalatedIncident /></ProtectedRoute>} />
            <Route path="incident/tickets" element={<ProtectedRoute><Tickets /></ProtectedRoute>} />
            <Route path="LWBot" element={<ProtectedRoute><LWBot /></ProtectedRoute>} />
            <Route path="dashboard/cost" element={<ProtectedRoute><Cost /></ProtectedRoute>} />
            <Route path="dashboard/inventory" element={<ProtectedRoute><Inventory /></ProtectedRoute>} />
            <Route path="dashboard/inventoryconfig" element={<ProtectedRoute><InventoryConfig /></ProtectedRoute>} />
            <Route path="dashboard/safety" element={<ProtectedRoute><Safety /></ProtectedRoute>} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider> 
  );
};

export default App;
