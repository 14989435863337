import React, { useState, useRef, useEffect } from "react";
import { IoSendSharp } from "react-icons/io5";
import "./LWBot.css";
import { Button, notification, Table } from "antd";
import { VscRobot } from "react-icons/vsc";

const LWBot = () => {
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("chatMessages");
    return savedMessages
      ? JSON.parse(savedMessages)
      : [
          {
            id: 1,
            text: "Welcome! How can we assist you today?",
            sender: "bot",
          },
        ];
  });
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const chatBodyRef = useRef(null);

  const handleSend = async () => {
    if (inputValue.trim()) {
      const newMessage = { id: Date.now(), text: inputValue, sender: "user" };
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, newMessage];
        localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
        return updatedMessages;
      });
      setInputValue("");
      setLoading(true);

      try {
        const url = "query";
        const response = await postApiRequest(url, { prompt: inputValue });

        if (response.table) {
          const tableMessage = {
            id: Date.now(),
            tableData: response.table.data,
            tableColumns: response.table.columns,
            sender: "bot",
          };
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, tableMessage];
            localStorage.setItem(
              "chatMessages",
              JSON.stringify(updatedMessages)
            );
            return updatedMessages;
          });
        } else {
          const botMessage = {
            id: Date.now(),
            text: response.response,
            sender: "bot",
          };
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, botMessage];
            localStorage.setItem(
              "chatMessages",
              JSON.stringify(updatedMessages)
            );
            return updatedMessages;
          });
        }
      } catch (error) {
        const errorMessage = {
          id: Date.now(),
          text: "Response not in our database",
          sender: "bot",
        };
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages, errorMessage];
          localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
          return updatedMessages;
        });
        notification.error({
          message: "Error",
          description: error.message || "Something went wrong.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  const handleClear = () => {
    localStorage.removeItem("chatMessages");
    setMessages([
      {
        id: 1,
        text: "Welcome! How can we assist you today?",
        sender: "bot",
      },
    ]);
  };

  const postApiRequest = async (url, payload) => {
    const token = localStorage.getItem("LWAI-token");
    //for local
    // const baseUrl = "http://13.200.172.102:8000";
    // const fullUrl = `${baseUrl}/${url}`;

    //use for versel and server
     const fullUrl = `/chatapi/${url}`;

    try {
      const response = await fetch(fullUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (response.ok) {
        return result;
      } else {
        throw new Error(result.error?.message || "Something went wrong");
      }
    } catch (error) {
      throw new Error(error.message || "Network error");
    }
  };

  return (
    <div className="h-screen flex flex-col bg-gray-100">
      <div className="flex items-center justify-between p-3 border-b border-gray-100 bg-white sticky top-0">
        <h2 className="text-xl font-bold text-gray-800">Ask LWBot</h2>
        <Button
          onClick={handleClear}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
        >
          Clear
        </Button>
      </div>

      <div className="flex-grow overflow-y-auto p-4" ref={chatBodyRef}>
        {messages.map((msg) =>
          msg.tableData ? (
            <Table
              key={msg.id}
              dataSource={msg.tableData}
              columns={msg.tableColumns.map((col) => ({
                title: col,
                dataIndex: col,
                key: col,
              }))}
              pagination={false}
              className="mb-4 shadow-sm bg-white rounded-lg"
            />
          ) : (
            <>
              <div className="flex items-center mb-1">
                {msg.sender === "bot" && (
                  <div className="flex items-center justify-center text-2xl mr-2">
                    <VscRobot />
                  </div>
                )}
                <div
                  key={msg.id}
                  className={`p-3 rounded-lg max-w-xs ${
                    msg.sender === "user"
                      ? "ml-auto bg-blue-600 text-white"
                      : "mr-auto bg-gray-200 text-gray-800"
                  }`}
                >
                  {msg.text}
                </div>
              </div>
            </>
          )
        )}

        {loading && (
          <div className="flex items-center justify-start space-x-2 mt-4">
            <span className="dot bg-blue-500 w-2 h-2 rounded-full bounce delay-0"></span>
            <span className="dot bg-blue-500 w-2 h-2 rounded-full bounce delay-200"></span>
            <span className="dot bg-blue-500 w-2 h-2 rounded-full bounce delay-400"></span>
          </div>
        )}
      </div>

      <div className="flex items-center p-2 border-t border-gray-300 bg-white">
        <input
          type="text"
          placeholder="Type a message..."
          className="flex-grow outline-none px-4 py-2 rounded-md border border-gray-300 focus:border-blue-500"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSend()}
        />
        <IoSendSharp
          className="text-blue-500 cursor-pointer ml-3 text-2xl hover:text-blue-600 transition"
          onClick={handleSend}
        />
      </div>
    </div>
  );
};

export default LWBot;
