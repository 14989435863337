export const putApi = async (url, payload) => {
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("LWAI-token");
  // console.log("envurl", process.env.REACT_APP_BASE_URL);
  // const fullUrl = `${baseUrl}/${url}`;
  const fullUrl = `/api/${url}`; // Use the Vercel proxy here

  try {
    const response = await fetch(fullUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify(payload),
    });

    const result = await response.json();

    if (response.ok) {
      // Return the successful result (with JWT and user details)
      return result;
    } else {
      // Throw an error with the message from the response
      throw new Error(result.error?.message || "Something went wrong");
    }
  } catch (error) {
    // Catch network or other errors
    throw new Error(error.message || "Network error");
  }
};
