import React from "react";
import { Button, Spin } from "antd";
import FormComponent from "../../../components/Formcomponent";

const DatabaseConfig = ({
  selectedFormFields,
  selectedDB,
  setSelectedDB,
  databases,
  control,
  errors,
  handleSubmit,
  reset,
  testBtnLoading,
  handleAddClick,
  onSubmitFTP,
  onSubmitDB,
  loading,
  connectedDatabases,
  connectedFTPs,
  handleSelect,
  selectedItem,
}) => {
  const selectedDatabase = databases.find((db) => db.name === selectedDB);

  return (
    <div>
      {selectedDB ? (
        <div className="flex" style={{ height: "78vh" }}>
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">
                Configure your {selectedDB} account
              </h2>
              <Button
                onClick={() => {
                  setSelectedDB(null);
                  reset();
                }}
              >
                Discard
              </Button>
            </div>
            <div style={{ overflowY: "auto" }}>
              <div className="flex mt-2 p-2">
                <div
                  className={`flex items-center justify-center w-10 h-10 bg-gray-50 rounded-full`}
                >
                  <div>
                    {selectedDatabase?.icon &&
                      React.cloneElement(selectedDatabase.icon, {
                        className: "text-xl",
                        style: { color: selectedDatabase.color },
                      })}
                  </div>
                </div>
                <div className="ml-2">
                  <p className="com-heading">
                    {`Configure ${selectedDB} for your account`}
                  </p>
                  <p className="agg-value mt-1">{`${selectedDB} Database`}</p>
                </div>
              </div>

              <div className="p-3">
                <FormComponent
                  formFields={selectedFormFields}
                  handleSubmit={handleSubmit}
                  control={control}
                  errors={errors}
                  onSubmit={selectedDB !== "FTP" ? onSubmitDB : onSubmitFTP}
                  buttonName={
                    selectedDB !== "FTP"
                      ? "Test and Create DBConnection"
                      : "Test and Create FTPConnection"
                  }
                  buttonLoading={testBtnLoading}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex space-x-1" style={{ height: "78vh" }}>
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">
                Most Used Integration Databases
              </h2>
            </div>
            <div className="flex-1 overflow-y-auto">
              <ul className="mt-2">
                {databases.map((db) => (
                  <li
                    key={db.name}
                    className="flex items-center justify-between p-4 mb-4 mx-2 bg-white rounded-lg shadow-sm border border-gray-200"
                  >
                    <div className="flex items-center space-x-3">
                      <span className="text-3xl" style={{ color: db.color }}>
                        {db.icon}
                      </span>
                      <span className="db-list">{db.name}</span>
                    </div>
                    <Button onClick={() => handleAddClick(db.name)}>Add</Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="flex-1 flex flex-col border-l overflow-hidden">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Connected Database</h2>
            </div>
            <div className="flex-1 overflow-y-auto">
              {loading ? (
                <div className="flex justify-center items-center h-full">
                  <Spin size="large" />
                </div>
              ) : (
                <ul className="mt-2">
                  {[...connectedDatabases, ...connectedFTPs].map(
                    (item, index) => (
                      <li
                        key={index}
                        className={`p-4 mb-4 mx-2 cursor-pointer transition-all duration-200 ease-in-out transform ${
                          selectedItem === item.id
                            ? "bg-blue-200 shadow-md"
                            : "bg-white hover:bg-blue-50"
                        } rounded-lg shadow-sm border border-gray-200`}
                        onClick={() => handleSelect(item)}
                      >
                        <div className="mb-2">
                          <span
                            className="com-heading"
                            style={{ color: "#1E90FF" }}
                          >
                            Host:
                          </span>{" "}
                          <span className="com-value">{item.host}</span>
                        </div>
                        <div className="mb-2">
                          <span
                            className="com-heading"
                            style={{ color: "#10B981" }}
                          >
                            Database/FTP Type:
                          </span>{" "}
                          <span className="com-value">
                            {item.dbtype || item.filetype}
                          </span>
                        </div>
                        <div>
                          <span
                            className="com-heading"
                            style={{ color: "#A855F7" }}
                          >
                            Database/FTP Name:
                          </span>{" "}
                          <span className="com-value">
                            {item.dbname || item.filename}
                          </span>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatabaseConfig;
