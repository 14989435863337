import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { FaSignal, FaSlack, FaWhatsapp } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { getApi } from "../../../utils/getApiMethod";
import { postApi } from "../../../utils/postApiMethod";
import { deleteApi } from "../../../utils/deleteApiMethod";
import TicketsFormComponent from "../../../components/TicketsFormComponent";
import { PiGreaterThanOrEqual } from "react-icons/pi";
import { PiGreaterThan } from "react-icons/pi";

const TicketSchema = yup.object().shape({
  operator_yellow: yup.string().required("Operator (Yellow) is required"),
  time_range_yellow: yup
    .number()
    .required("Time Range (Yellow) is required")
    .positive("Time Range (Yellow) must be positive"),

  operator_red: yup.string().required("Operator (Red) is required"),
  time_range_red: yup
    .number()
    .required("Time Range (Red) is required")
    .positive("Time Range (Red) must be positive"),

  channels: yup.array().min(1, "At least one channel must be selected"),
  alerto: yup.array().min(1, "At least one alert option must be selected"),
});

const TicketConfig = ({
  selSubMetric,
  setNextButtonEnable,
  submetricMetricId,
  selectedAlarm,
}) => {
  // console.log("selSubMetric", selSubMetric);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(TicketSchema),
    defaultValues: {
      operator_yellow: "",
      time_range_yellow: "",
      operator_red: "",
      time_range_red: "",
      channels: [],
      alerto: [],
    },
  });

  const [contactList, setContactsList] = useState([]);
  const [ticketListRes, setTicketListRes] = useState([]);

  const getTicketsLists = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setTicketListRes(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = `ticket-config/resource?column=metricid&value=${
      submetricMetricId || 0
    }`;
    getTicketsLists(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submetricMetricId]);

  const onSubmit = async (data) => {
    const yellowTickets = ticketListRes.filter((ele) => ele.time_range_yellow);
    const redTickets = ticketListRes.filter((ele) => ele.time_range_red);

    // Condition to check if there's already one yellow and one red ticket
    if (yellowTickets.length === 1 && redTickets.length === 1) {
      notification.warning({
        message: "Warning",
        description:
          "You already have one ticket with yellow status and one with red status. Please resolve them before creating a new ticket.",
      });
      return;
    }

    const modifiedData = {
      ...data,
      metricid: submetricMetricId,
      submetricname: selSubMetric.metricname || "",
      time_range_red: data.time_range_red || "",
      time_range_yellow: data.time_range_yellow || "",
      operator_yellow: data.operator_yellow || "",
      operator_red: data.operator_red || "",
      alerto: data.alerto || [],
      channels: data.channels || [],
    };

    // Remove undefined values
    Object.keys(modifiedData).forEach((key) => {
      if (modifiedData[key] === undefined || modifiedData[key] === "") {
        delete modifiedData[key];
      }
    });

    const url = `ticket-config/resource`;
    try {
      const response = await postApi(url, modifiedData);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        reset();
        const url = `ticket-config/resource?column=metricid&value=${
          submetricMetricId || 0
        }`;
        getTicketsLists(url);
        setNextButtonEnable(false);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
      setNextButtonEnable(true);
    }
  };

  const handleRemoveAlarm = async (ele) => {
    // console.log("remove clicked...", ele);
    setNextButtonEnable(false);
    const url = `ticket-config/resource/${ele.id}`;
    try {
      const response = await deleteApi(url);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
          // description: error.message,
        });
        const url = `ticket-config/resource?column=metricid&value=${submetricMetricId}`;
        getTicketsLists(url);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const formFields = [
    {
      name: "yellowHeading",
      label: "Create Ticket with Status Yellow",
      type: "heading",
    },
    {
      name: "operator_yellow",
      label: "Operator",
      type: "select",
      icon: FaSignal,
      options: [
        {
          value: "greater",
          label: "Greater",
          icon: PiGreaterThan,
          color: "red",
        },
        {
          value: "greaterEqual",
          label: "Greater Equal",
          icon: PiGreaterThanOrEqual,
          color: "red",
        },
      ],
    },
    {
      name: "frequencyTypeYellow",
      label: "Frequency Type",
      type: "input",
      defaultValue: "Minutes",
      disabled: true,
    },
    {
      name: "time_range_yellow",
      label: "Frequency Range",
      type: "input",
      placeholder: "Enter time range for Yellow",
    },
    {
      name: "redHeading",
      label: "Create Ticket with Status Red",
      type: "heading",
    },
    {
      name: "operator_red",
      label: "Operator",
      type: "select",
      icon: FaSignal,
      options: [
        {
          value: "greater",
          label: "Greater",
          icon: PiGreaterThan,
          color: "red",
        },
        {
          value: "greaterEqual",
          label: "Greater Equal",
          icon: PiGreaterThanOrEqual,
          color: "red",
        },
      ],
    },
    {
      name: "frequencyTypeRed",
      label: "Frequency Type",
      type: "input",
      defaultValue: "Minutes",
      disabled: true,
    },
    {
      name: "time_range_red",
      label: "Frequency Range",
      type: "input",
      placeholder: "Enter time range for Red",
    },
    {
      name: "channels",
      label: "Channels",
      type: "select",
      icon: FaWhatsapp,
      options: [
        {
          value: "whatsapp",
          label: "WhatsApp",
          icon: FaWhatsapp,
          color: "green",
        },
        {
          value: "email",
          label: "Email",
          icon: AiOutlineMail,
          color: "blue",
        },
        {
          value: "slack",
          label: "Slack",
          icon: FaSlack,
          color: "purple",
        },
      ],
      multiple: true,
    },
    {
      name: "alerto",
      label: "Alert To",
      type: "select",
      icon: AiOutlineUser,
      options: contactList,
      multiple: true,
    },
  ];

  const getContactsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data?.rows) {
        const options = result?.data?.rows.map((item) => ({
          value: item.id,
          label: item.name,
          icon: AiOutlineUser,
          color: "black",
        }));
        setContactsList(options);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "contact/resource";
    getContactsList(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAlertToNames = (alertIds, contactList) => {
    // console.log("alertIds",alertIds)
    // console.log("contactList",contactList)

    if (!Array.isArray(alertIds)) return "";

    return alertIds
      .map(
        (id) =>
          contactList.find((contact) => contact.value === parseInt(id))?.label
      )
      .filter(Boolean)
      .join(", ");
  };

  useEffect(() => {
    const hasYellowTicket =
      ticketListRes.filter((ele) => ele.time_range_yellow).length === 1;
    const hasRedTicket =
      ticketListRes.filter((ele) => ele.time_range_red).length === 1;

    if (hasYellowTicket && hasRedTicket) {
      setNextButtonEnable(false);
    } else {
      setNextButtonEnable(true);
    }
  }, [ticketListRes, setNextButtonEnable]);

  return (
    <>
      <div className="flex space-x-2" style={{ height: "78vh" }}>
        <div className="flex-1 flex flex-col bg-gray-50 overflow-hidden">
          <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h2 className="cus-h2-heading">
              {`Configure Tickets for a ${selSubMetric.metricname}`}
            </h2>
          </div>
          <div
            className="flex-1 space-x-6 overflow-y-auto"
            style={{ height: "100vh" }}
          >
            <div className="p-3">
              <TicketsFormComponent
                formFields={formFields}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                buttonName={"Submit"}
              />
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col bg-gray-50 overflow-hidden border-l">
          <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h2 className="cus-h2-heading">Created Tickets</h2>
          </div>
          <div className="m-2" style={{ overflowY: "auto" }}>
            {ticketListRes.length > 0 ? (
              <>
                {/* Ticket with Status Yellow*/}
                {ticketListRes.some((ele) => ele.time_range_yellow) && (
                  <>
                    <h3 className="ml-2 mt-2">
                      <span className="p-2 ticket-heading bg-yellow-200 rounded-md">
                        Ticket with Status Yellow
                      </span>
                    </h3>
                    <ul>
                      {ticketListRes
                        .filter((ele) => ele.time_range_yellow)
                        .map((ele, index) => (
                          <li
                            key={index}
                            className={`p-4 mb-4 mx-2 mt-4 ${
                              selectedAlarm === ele.id
                                ? "bg-blue-200"
                                : "bg-white"
                            } rounded-lg shadow-sm border border-gray-200`}
                          >
                            <div className="mb-2 flex justify-between items-center">
                              <div>
                                <span
                                  className="com-heading"
                                  style={{ color: "#C81AE1" }}
                                >
                                  Operator:
                                </span>{" "}
                                <span className="com-value">
                                  {ele.operator_yellow}
                                </span>
                              </div>
                              <button
                                className="text-red-600 hover:text-red-800 transition-colors p-1 rounded-full hover:bg-red-100"
                                onClick={() => handleRemoveAlarm(ele)}
                              >
                                <IoClose size={22} />
                              </button>
                            </div>

                            <div className="mb-2">
                              <span
                                className="com-heading"
                                style={{ color: "#10b935" }}
                              >
                                Frequency:
                              </span>{" "}
                              <span className="com-value">
                                {ele.time_range_yellow + "Minutes"}
                              </span>
                            </div>
                            <div className="mb-2">
                              <span
                                className="com-heading"
                                style={{ color: "#1E90FF" }}
                              >
                                Channels:
                              </span>{" "}
                              <span className="com-value">
                                {Array.isArray(ele.channels)
                                  ? ele.channels.join(", ")
                                  : ele.channels}
                              </span>
                            </div>
                            <div className="mb-2">
                              <span
                                className="com-heading"
                                style={{ color: "#E19F1A" }}
                              >
                                Alert To:
                              </span>{" "}
                              <span className="com-value">
                                {getAlertToNames(ele.alerto, contactList)}
                              </span>
                              {/* <span className="com-value">{ele.alerto}</span> */}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </>
                )}

                {/* Ticket with Status Red*/}
                {ticketListRes.some((ele) => ele.time_range_red) && (
                  <>
                    <h3 className="ml-2">
                      <span className="p-2 ticket-heading bg-red-200 rounded-md">
                        Ticket with Status Red
                      </span>
                    </h3>
                    <ul>
                      {ticketListRes
                        .filter((ele) => ele.time_range_red)
                        .map((ele, index) => (
                          <li
                            key={index}
                            className={`p-4 mb-4 mx-2 mt-4 ${
                              selectedAlarm === ele.id
                                ? "bg-blue-200"
                                : "bg-white"
                            } rounded-lg shadow-sm border border-gray-200`}
                          >
                            <div className="mb-2 flex justify-between items-center">
                              <div>
                                <span
                                  className="com-heading"
                                  style={{ color: "#C81AE1" }}
                                >
                                  Operator:
                                </span>{" "}
                                <span className="com-value">
                                  {ele.operator_red}
                                </span>
                              </div>
                              <button
                                className="text-red-600 hover:text-red-800 transition-colors p-1 rounded-full hover:bg-red-100"
                                onClick={() => handleRemoveAlarm(ele)}
                              >
                                <IoClose size={22} />
                              </button>
                            </div>

                            <div className="mb-2">
                              <span
                                className="com-heading"
                                style={{ color: "#10b935" }}
                              >
                                Frequency:
                              </span>{" "}
                              <span className="com-value">
                                {ele.time_range_red + "Minutes"}
                              </span>
                            </div>
                            <div className="mb-2">
                              <span
                                className="com-heading"
                                style={{ color: "#1E90FF" }}
                              >
                                Channels:
                              </span>{" "}
                              <span className="com-value">
                                {Array.isArray(ele.channels)
                                  ? ele.channels.join(", ")
                                  : ele.channels}
                              </span>
                            </div>
                            <div className="mb-2">
                              <span
                                className="com-heading"
                                style={{ color: "#E19F1A" }}
                              >
                                Alert To:
                              </span>{" "}
                              <span className="com-value">
                                {getAlertToNames(ele.alerto, contactList)}
                              </span>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </>
            ) : (
              <p className="p-2 m-2 border border-gray-200 rounded-lg bg-gradient-to-r from-blue-50 to-white shadow-md cursor-pointer transition-transform hover:bg-blue-50">
                No Tickets created yet.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketConfig;
