import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { FaGoogle } from "react-icons/fa";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import logo from "../../assests/linkworks_logo.png";
import { postApi } from "../../utils/postApiMethod";
import { useAuth } from "../../AuthContext";

const schema = yup
  .object({
    email: yup.string().required("email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const onSubmit = async (data) => {
  //   const payload = {
  //     EMAIL: data.email,
  //     PASSWORD: data.password,
  //   };
  //   const url = "login";
  //   try {
  //     const response = await postApi(url, payload);
  //     if (response?.statusCode === 200) {
  //       localStorage.setItem("LWAI-token", response.token);
  //       navigate("/app/home");
  //     }
  //   } catch (error) {
  //     console.error("SignIn failed:", error.message);
  //     alert(error.message);
  //   }
  // };

  const onSubmit = async (data) => {
    const payload = {
      EMAIL: data.email,
      PASSWORD: data.password,
    };
    const url = "login";
    try {
      const response = await postApi(url, payload);
      if (response?.statusCode === 200) {
        login(response.token); // Update login state
        // navigate("/app/home");
        navigate("/app/home", { state: { selectedKey: "home" } });
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      alert(error.message);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img src={logo} alt="Company Logo" className="h-12" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              id="email"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              {...register("email")}
              placeholder="email"
            />
            <p className="text-red-500 text-xs mt-1">{errors.email?.message}</p>
          </div>
          <div className="mb-4 relative">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              id="password"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              type={showPassword ? "text" : "password"}
              {...register("password")}
              placeholder="Password"
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <AiOutlineEyeInvisible className="text-gray-500 mt-6" />
              ) : (
                <AiOutlineEye className="text-gray-500 mt-6" />
              )}
            </div>
            <p className="text-red-500 text-xs mt-1">
              {errors.password?.message}
            </p>
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white font-bold rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Sign In
          </button>
        </form>
        <div className="flex items-center my-6">
          <hr className="flex-1 border-gray-300" />
          <span className="mx-4 text-gray-500">OR</span>
          <hr className="flex-1 border-gray-300" />
        </div>
        <div className="text-center">
          <button className="flex items-center justify-center w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            <span className="inline-flex items-center">
              <FaGoogle className="mr-2 text-green-500" />
              <span className="text-black">Sign in with Google</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
