import { Button, notification, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BsFillPersonLinesFill } from "react-icons/bs";
import "./Contact.css";
import FormComponent from "../../components/Formcomponent";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { getApi } from "../../utils/getApiMethod";
import { FaWhatsapp } from "react-icons/fa";
import { PiSlackLogo } from "react-icons/pi";
import { postApi } from "../../utils/postApiMethod";
import { putApi } from "../../utils/putApiMethod";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required"),
  slack: yup.string().required("Slack id is required"),
  whatsapp: yup.string().required("Whatsapp Number is required"),
});

const Contact = () => {
  const [contactList, setContactsList] = useState([]);
  const [isContactForm, setIsContactForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      slack: "",
      whatsapp: "",
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Slack Id",
      dataIndex: "slack",
      key: "slack",
    },
    {
      title: "Whatsapp Number",
      dataIndex: "whatsapp",
      key: "whatsapp",
    },
  ];

  const getContactsList = async (url) => {
    setLoading(true);
    try {
      const result = await getApi(url);
      if (result?.data?.rows) {
        const contactsWithKeys = result.data.rows.map((contact) => ({
          ...contact,
          key: contact.id,
        }));
        // setContactsList(result?.data?.rows);
        setContactsList(contactsWithKeys);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const url = "contact/resource";
    getContactsList(url);
  }, []);

  const handleAddClick = () => {
    reset({
      name: "",
      email: "",
      phone: "",
    });
    setIsContactForm(true);
  };

  const handleDiscard = () => {
    reset({
      name: "",
      email: "",
      slack: "",
      whatsapp: "",
    });
    setIsContactForm(false);
  };

  const onSubmit = async (data) => {
    setSaveLoading(true);
    // console.log("Connection Data:", data);
    const payload = {
      name: data.name,
      email: data.email,
      slack: data.slack,
      whatsapp: data.whatsapp,
    };
    const url = isEdit ? `contact/resource/${currentId}` : "contact/resource";
    try {
      const apiMethod = isEdit ? putApi : postApi;
      const response = await apiMethod(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        const url = "contact/resource";
        getContactsList(url);
        setIsContactForm(false);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    } finally {
      setSaveLoading(false);
    }
  };

  const handleNameClick = (record) => {
    // console.log("Resetting form with record data:", record);
    setIsContactForm(true);
    setCurrentId(record.id);
    setIsEdit(true);
    reset({
      name: record.name,
      email: record.email,
      slack: record.slack,
      whatsapp: record.whatsapp,
    });
  };

  const formFields = [
    { name: "name", label: "Name", type: "input", icon: AiOutlineUser },
    { name: "email", label: "Email", type: "input", icon: AiOutlineMail },
    { name: "slack", label: "Slack Id", type: "input", icon: PiSlackLogo },
    {
      name: "whatsapp",
      label: "Whatsapp Number",
      type: "input",
      icon: FaWhatsapp,
    },
  ];

  return (
    <>
      {isContactForm ? (
        <div className="flex space-x-6" style={{ height: "100vh" }}>
          <div className="flex-1">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Create Contact Form</h2>
              <Button onClick={handleDiscard}>Discard</Button>
            </div>
            <div className="flex mt-2 p-2">
              <div
                className={`flex items-center justify-center w-10 h-10 bg-gray-50 rounded-full`}
              >
                <div>
                  <BsFillPersonLinesFill className="text-xl" />
                </div>
              </div>
              <div className="ml-2">
                <p className="com-heading">
                  {`Configure details for your account`}
                </p>
                <p className="agg-value mt-1">{`Personal Details`}</p>
              </div>
            </div>
            <div className="p-3 overflow-y-auto">
              <FormComponent
                formFields={formFields}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                buttonName={"Save"}
                buttonLoading={saveLoading}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex" style={{ height: "100vh" }}>
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Contacts List</h2>
              <Button onClick={() => handleAddClick()}>Add</Button>
            </div>
            {/* <div className="p-3">
              {loading ? (
                <div className="flex justify-center items-center h-full">
                  <Spin size="large" />
                </div>
              ) : (
                <Table
                  dataSource={contactList}
                  columns={columns}
                  scroll={{ y: 400 }}
                  pagination={{ pageSize: 10 }}
                />
              )}
            </div> */}
            <Table
              dataSource={contactList}
              columns={columns}
              scroll={{ y: 400 }}
              pagination={{ pageSize: 10 }}
              loading={{
                spinning: loading,
                indicator: <Spin size="large" />,
              }}
              onRow={(record) => ({
                onClick: () => handleNameClick(record),
              })}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;
