export const deleteApi = async (url) => {
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("LWAI-token");

  // const fullUrl = `${baseUrl}/${url}`;
  const fullUrl = `/api/${url}`; // Use the Vercel proxy here

  try {
    const response = await fetch(fullUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error?.message || "Failed to fetch data");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in deleteApi:", error);
    throw error;
  }
};
