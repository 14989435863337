import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, Space } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getApi } from "../../../utils/getApiMethod";
import { postApi } from "../../../utils/postApiMethod";

const validationSchema = yup.object().shape({
  esclimitvalue: yup.string().required("Escalation Limit Value is required"),
  levels: yup.array().of(
    yup.object().shape({
      level: yup.string().required("Level is required"),
      assignee: yup.string().required("Assignee is required"),
    })
  ),
});

const EscalationConfig = ({
  selectedMetricForEscalation,
  submetricMetricId,
  nonLowSeverities,
}) => {
  const severityLabels = {
    medium: "Medium Severity",
    high: "High Severity",
  };

  const [selectedSeverity, setSelectedSeverity] = useState(null);

  const handleSeverityChange = (value) => {
    console.log("valueseverity", value);
    setSelectedSeverity(value);
  };

  const [escalationListRes, setEscalationList] = useState([]);

  const getEscalationConfigLists = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        // console.log("escalationListRes", escalationListRes);
        setEscalationList(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = `escalation?metricid=${submetricMetricId}`;
    getEscalationConfigLists(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submetricMetricId]);

  const [contactList, setContactsList] = useState([]);

  const getContactsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data?.rows) {
        const contactsWithKeys = result.data.rows.map((contact) => ({
          ...contact,
          key: contact.id,
        }));
        // setContactsList(result?.data?.rows);
        setContactsList(contactsWithKeys);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "contact/resource";
    getContactsList(url);
  }, []);

  const [levelCount, setLevelCount] = useState(0);
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      esclimitvalue: "",
      levels: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append } = useFieldArray({
    control,
    name: "levels",
  });

  const handleAddLevel = () => {
    const currentLevels = getValues("levels");
    const hasEmptyAssignee = currentLevels.some((level) => !level.assignee);

    if (hasEmptyAssignee) {
      notification.warning({
        message:
          "All existing levels must have an Assignee before adding a new level.",
      });
      return;
    }
    const nextLevel = `L${levelCount + 1}`;
    append({ level: nextLevel, assignee: "" });
    setLevelCount((prev) => prev + 1);
  };

  const handleReset = () => {
    reset({ esclimitvalue: "", levels: [] });
    setLevelCount(0);
  };

  const onSubmit = async (data) => {
    const hasEmptyAssignee = data.levels.some((level) => !level.assignee);

    if (hasEmptyAssignee) {
      notification.warning({
        message: "Please fill in all Assignee fields before submitting.",
      });
      return;
    }

    // Check for existing escalations with the same severity
    const existingEscalations = escalationListRes.some((escalation) => {
      return escalation.TV_ESCAL01s.some(
        (escal) => escal.severity === selectedSeverity
      );
    });

    if (existingEscalations) {
      notification.warning({
        message: `An escalation for ${selectedSeverity} severity already exists. You cannot add another one.`,
      });
      return;
    }

    const transformedLevels = data.levels.map((level) => ({
      assignto: level.assignee,
      level: parseInt(level.level.replace("L", ""), 10),
    }));

    const payload = {
      levels: transformedLevels,
      esc_limit_value: data.esclimitvalue,
      metricid: submetricMetricId,
      severity: selectedSeverity,
      submetricname: selectedMetricForEscalation.metricname || "",
    };
    console.log("payload", payload);

    const url = `escalation`;
    try {
      const response = await postApi(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        handleReset();
        setSelectedSeverity(null);
        const url = `escalation?metricid=${submetricMetricId}`;
        getEscalationConfigLists(url);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  return (
    <>
      <div className="flex space-x-2" style={{ height: "78vh" }}>
        <div className="flex-1 flex flex-col bg-white overflow-hidden">
          <div className="p-3 flex items-center justify-between bg-gray-50 sticky top-0 z-10">
            <h2 className="cus-h2-heading">{`Configure Escalations for ${selectedMetricForEscalation.metricname}`}</h2>
          </div>
          <div className="m-2" style={{ overflowY: "auto" }}>
            <div className="flex ml-4 mt-2 ">
              <Select
                placeholder="Select Severity Level"
                value={selectedSeverity}
                onChange={handleSeverityChange}
                style={{ width: 180, height: 40 }}
              >
                {nonLowSeverities.map((severity, index) => (
                  <Select.Option key={`${severity}-${index}`} value={severity}>
                    {severityLabels[severity]}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="flex-1 space-x-6" style={{ height: "70vh" }}>
              <Form
                onFinish={handleSubmit(onSubmit)}
                layout="vertical"
                style={{ padding: "20px" }}
              >
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: "100%" }}
                >
                  <Form.Item
                    label="Escalation Consecutive Days"
                    style={{ width: "100%" }}
                  >
                    <Controller
                      name="esclimitvalue"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <Input
                            {...field}
                            placeholder="Enter Escalation Consecutive Days"
                            className="mt-1 bg-white"
                            style={{ borderRadius: "4px", padding: "10px" }}
                          />
                          {fieldState.error && (
                            <p style={{ color: "red", marginTop: "4px" }}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </Form.Item>

                  <Button icon={<AiOutlinePlus />} onClick={handleAddLevel}>
                    Add Level
                  </Button>

                  {fields.map((field, index) => (
                    <Space
                      key={field.id}
                      direction="horizontal"
                      size="middle"
                      style={{ width: "100%" }}
                    >
                      <Form.Item label="Level" style={{ flex: 1 }}>
                        <Controller
                          name={`levels[${index}].level`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              readOnly
                              style={{ borderRadius: "4px", padding: "10px" }}
                            />
                          )}
                        />
                      </Form.Item>

                      <Form.Item label="Assignee" style={{ flex: 2 }}>
                        <Controller
                          name={`levels[${index}].assignee`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              <Select
                                {...field}
                                placeholder="Select assignee"
                                options={contactList.map((contact) => ({
                                  label: contact.name,
                                  value: contact.id,
                                }))}
                                style={{
                                  width: "300px",
                                  height: "44px",
                                  borderRadius: "7px",
                                  border: fieldState.error
                                    ? "1px solid red"
                                    : undefined,
                                }}
                              />
                            </>
                          )}
                        />
                      </Form.Item>
                    </Space>
                  ))}

                  <Space size="middle">
                    <Button onClick={handleReset}>Reset</Button>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Space>
                </Space>
              </Form>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col bg-white overflow-hidden border-l">
          <div className="p-3 flex items-center justify-between bg-gray-50 sticky top-0 z-10">
            <h2 className="cus-h2-heading">Created Escalations</h2>
          </div>
          <div className="m-2" style={{ overflowY: "auto" }}>
            {escalationListRes.length > 0 ? (
              <ul>
                {escalationListRes.map((ele, index) => (
                  <li
                    key={index}
                    className="p-4 m-2 border border-gray-200 rounded-lg bg-gradient-to-r from-blue-50 to-white shadow-md cursor-pointer transition-transform transform flex flex-col"
                  >
                    {/* Display the details inside TV_ESCAL01s */}
                    {ele.TV_ESCAL01s.length > 0 && (
                      <ul className="mt-2 pl-4">
                        {/* Use a Set to track unique messages */}
                        {ele.TV_ESCAL01s.reduce((acc, escal) => {
                          const message = `Escalation created for the ${
                            escal.submetricname || "undefined"
                          } submetric with severity ${escal.severity} for ${
                            ele.esc_limit_value
                          } consecutive days.`;

                          // Check if the message is already added
                          if (!acc.includes(message)) {
                            acc.push(message);
                          }

                          return acc;
                        }, []).map((uniqueMessage, msgIndex) => (
                          <li key={msgIndex} className="my-2">
                            <span className="esc-lim-heading">
                              {uniqueMessage}
                            </span>
                            <ul className="mt-2 pl-4">
                              {ele.TV_ESCAL01s.filter((escal) => {
                                const msg = `Escalation created for the ${
                                  escal.submetricname || "undefined"
                                } submetric with severity ${
                                  escal.severity
                                } for ${ele.esc_limit_value} consecutive days.`;
                                return msg === uniqueMessage;
                              }).map((escal, subIndex) => (
                                <li
                                  key={subIndex}
                                  className="p-2 border border-gray-300 rounded-lg bg-white shadow-sm flex justify-between items-center mt-2"
                                >
                                  <span className="flex-grow">
                                    <span>
                                      <span className="esc-lvl-heading">
                                        Level:{" "}
                                      </span>
                                      <span className="esc-lvl-value">
                                        {escal.level}
                                      </span>
                                    </span>{" "}
                                    |
                                    <span className="ml-2">
                                      <span className="esc-lvl-heading">
                                        Assigned to:{" "}
                                      </span>
                                      <span className="esc-lvl-value">
                                        {escal.assignto}
                                      </span>
                                    </span>{" "}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="p-2 m-2 border border-gray-200 rounded-lg bg-gradient-to-r from-blue-50 to-white shadow-md cursor-pointer transition-transform transform hover:scale-105 hover:bg-blue-50">
                No Escalation created yet.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EscalationConfig;
