import React, { useEffect, useState } from "react";
import { Button, notification } from "antd";
import { Editor } from "@monaco-editor/react";
import { putApi } from "../../../utils/putApiMethod";

const SubMetricsConfig = ({
  selSubMetric,
  configuredDBSourceId,
  setNextButtonEnable,
  setSubmetricMetricId,
}) => {
  // console.log("selSubMetric", selSubMetric);
  const defaultQuery = selSubMetric.query ? selSubMetric.query : "";
  const [query, setQuery] = useState(defaultQuery);
  const [queryResult, setQueryResult] = useState("");
  const [sidAfterRunQuery, setSIDAfterRunQuery] = useState("");

  const handleRunQuery = async () => {
    const payload = {
      query: query,
      metricname: selSubMetric.metricname,
      sourceid: configuredDBSourceId,
      SID: selSubMetric.SID ? selSubMetric.SID : sidAfterRunQuery,
      id: selSubMetric.id,
    };
    // console.log("payload", payload);
    const url = `update-metric`;
    try {
      const response = await putApi(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        setQueryResult(
          response?.data?.queryResult || `Query Execution Failed...`
        );
        setSubmetricMetricId(response?.data?.metricid || "");
        setSIDAfterRunQuery(response?.data?.SID || "");
        setNextButtonEnable(false);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
      setNextButtonEnable(true);
    }
  };

  useEffect(() => {
    const newQuery = selSubMetric.query || "No query Found";
    setQuery(newQuery);
    if (selSubMetric.SID !== null) {
      handleRunQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selSubMetric]);

  return (
    <>
      <div className="flex" style={{ height: "78vh" }}>
        <div className="flex-1 flex flex-col border-r overflow-hidden">
          <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h3 className="cus-h2-heading">{selSubMetric.metricname}</h3>
          </div>

          <div className="flex flex-col flex-1 overflow-y-auto">
            <div className="flex-grow">
              <Editor
                height="calc(60vh - 40px)"
                language="sql"
                value={query}
                onChange={(newValue) => setQuery(newValue)}
                options={{
                  wordWrap: "on",
                  minimap: { enabled: false },
                  scrollBeyondLastLine: false,
                }}
              />
            </div>
            <div className="p-4 flex justify-between items-center bg-gray-50">
              <Button
                type="primary"
                onClick={handleRunQuery}
                className="w-20 ml-auto"
              >
                Run Query
              </Button>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h2 className="cus-h2-heading">Query Result</h2>
          </div>
          <div className="flex-1 p-3">
            {queryResult || "Query result will be displayed here."}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubMetricsConfig;
