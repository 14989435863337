import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Input, Select, Button, Switch } from "antd";

const FormComponent = ({
  formFields,
  handleSubmit,
  control,
  errors,
  onSubmit,
  buttonName,
  buttonLoading,
}) => {
  const [operator, setOperator] = useState("");
  const [severity, setSeverity] = useState("");

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mb-4">
      {formFields.map(
        ({ name, label, type, icon: Icon, options, multiple, conditional }) => {
          // Conditionally render fields based on conditional
          if (conditional === "greater_than" && operator !== "greater_than") {
            return null;
          }
          if (conditional === "equal_to" && operator !== "equal_to") {
            return null;
          }
          if (conditional === "less_than" && operator !== "less_than") {
            return null;
          }
          if (conditional === "between" && operator !== "between") {
            return null;
          }
          if (conditional === "high" && severity !== "high") {
            return null;
          }
          if (conditional === "medium" && severity !== "medium") {
            return null;
          }
          if (conditional === "low" && severity !== "low") {
            return null;
          }

          // if (conditional && conditional !== operator) {
          //   return null;
          // }

          return (
            <div key={name} className="flex flex-col space-y-2">
              <label className="com-heading flex items-center space-x-2">
                {Icon && <Icon className="text-xl" />}
                <span>{label}</span>
              </label>
              <Controller
                name={name}
                control={control}
                render={({ field }) => {
                  if (type === "input") {
                    if (name === "weightage" && severity === "high") {
                      return (
                        <Input
                          {...field}
                          size="large"
                          className="border-gray-300 rounded-md"
                          value={100}
                          disabled
                        />
                      );
                    }
                    if (name === "weightage" && severity === "medium") {
                      return (
                        <Input
                          {...field}
                          size="large"
                          className="border-gray-300 rounded-md"
                          value={60}
                          disabled
                        />
                      );
                    }
                    if (name === "weightage" && severity === "low") {
                      return (
                        <Input
                          {...field}
                          size="large"
                          className="border-gray-300 rounded-md"
                          value={30}
                          disabled
                        />
                      );
                    }
                    if (name === "ipaddress") {
                      return (
                        <Input
                          {...field}
                          size="large"
                          className="border-gray-300 rounded-md"
                          value={"13.202.35.121"}
                          disabled
                        />
                      );
                    }
                    return (
                      <Input
                        {...field}
                        size="large"
                        className="border-gray-300 rounded-md"
                      />
                    );
                  } else if (type === "select") {
                    return (
                      <Select
                        {...field}
                        size="large"
                        className="border-gray-300 rounded-md"
                        mode={multiple ? "multiple" : null}
                        value={
                          field.value !== undefined
                            ? field.value
                            : multiple
                            ? []
                            : ""
                        }
                        onChange={(value) => {
                          field.onChange(value);
                          if (name === "operator") {
                            setOperator(value); // Update operator value
                          } else if (name === "severity") {
                            setSeverity(value); // Update severity value
                          }
                        }}
                      >
                        {options.map((option) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            <div className="flex items-center space-x-2">
                              <option.icon style={{ color: option.color }} />
                              <span>{option.label}</span>
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    );
                  } else if (type === "switch") {
                    return (
                      <Switch
                        {...field}
                        style={{ width: "30px" }}
                        className="border-gray-300 rounded-xl"
                      />
                    );
                  } else if (type === "para") {
                    return (
                      <div className="flex items-center space-x-2">
                        <span className="cus-content">{name}</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                }}
              />
              {errors[name] && (
                <p className="text-red-500 text-sm">{errors[name].message}</p>
              )}
            </div>
          );
        }
      )}
      <div className="mt-4">
        <Button
          type="primary"
          htmlType="submit"
          className="w-full cus-btn-full-wid rounded-md shadow"
          loading={buttonLoading}
        >
          <span className="ml-2">{buttonName}</span>
        </Button>
      </div>
    </form>
  );
};

export default FormComponent;
