import React, { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { getApi } from "../../utils/getApiMethod";
import "./Visibility.css";
import { FaClipboardCheck, FaShippingFast } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import { FiTruck } from "react-icons/fi";
import { AiOutlineCheckSquare } from "react-icons/ai";

const VisibilityDetailpage = ({ num, isType, selectedRow }) => {
  const [visibilityDetailList, setVisibilityDetailList] = useState([]);
  const [visibilityStatusList, setVisibilityStatusList] = useState([]);

  const getVisibilityDetailList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setVisibilityDetailList(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("isType", isType);
    let url = "";
    if (isType === "inbound") {
      url = `visiibility-details?STINUM=${num}&sourceid=${2}`;
    } else {
      url = `visiibility-details?ORDNUM=${num}&sourceid=${2}`;
    }
    getVisibilityDetailList(url);
  }, [num, isType]);

  const getVisibilityStatusList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setVisibilityStatusList(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("isType", isType);
    let url = "";
    if (isType === "inbound") {
      url = `visibility?sourceid=${2}&STINUM=${num}`;
    } else {
      url = `visibility?sourceid=${2}&ORDNUM=${num}`;
    }
    getVisibilityStatusList(url);
  }, [num, isType]);

  const stepIconOutbound = {
    "Order Placed": <IoMdCheckmark className="text-xl" />,
    "Order Picked": <RiShoppingCart2Line spin className="text-xl" />,
    "Order Loaded": <FiTruck className="text-xl" />,
    "Loading Confirmed": <AiOutlineCheckSquare className="text-xl" />,
    "Order Shipped": <FaShippingFast className="text-xl" />,
    default: <CloseCircleOutlined className="text-xl" />,
  };

  const stepIconInbound = {
    truckCheckIn: <IoMdCheckmark className="text-2xl" />,
    assignDockDoor: <RiShoppingCart2Line spin className="text-2xl" />,
    asnReceiving: <FiTruck className="text-2xl" />,
    verifyReceipt: <AiOutlineCheckSquare className="text-2xl" />,
    putaway: <FaShippingFast className="text-2xl" />,
    default: <CloseCircleOutlined className="text-2xl" />,
  };

  const stepIcon = isType === "inbound" ? stepIconInbound : stepIconOutbound;

  const outboundItem = [
    {
      label: "Manifest Number",
      icon: <FaClipboardCheck className="text-green-500 text-xl" />,
      value: selectedRow?.MANIFESTNUM || "",
      bg: "bg-white",
      iconbg: "bg-green-100",
    },
    {
      label: "Order Type",
      icon: <FaClipboardCheck className="text-yellow-500 text-xl" />,
      value: selectedRow?.ORDTYPE || "",
      bg: "bg-white",
      iconbg: "bg-yellow-100",
    },
    {
      label: "Delivery Route",
      icon: <FaClipboardCheck className="text-pink-500 text-xl" />,
      value: selectedRow?.DELIVERYROUTE || "",
      bg: "bg-white",
      iconbg: "bg-pink-100",
    },
    {
      label: "Order Class",
      icon: <FaClipboardCheck className="text-orange-500 text-xl" />,
      value: selectedRow?.ORDCLASS || "",
      bg: "bg-white",
      iconbg: "bg-orange-100",
    },
    {
      label: "Order Number",
      icon: <FaClipboardCheck className="text-blue-500 text-xl" />,
      value: selectedRow?.ORDNUM || "",
      bg: "bg-white",
      iconbg: "bg-blue-100",
    },
  ];

  const inboundItem = [
    {
      label: "STI Number",
      icon: <FaClipboardCheck className="text-green-500 text-xl" />,
      value: selectedRow?.STINUM || "",
      bg: "bg-white",
      iconbg: "bg-green-100",
    },
    {
      label: "ASN Number",
      icon: <FaClipboardCheck className="text-yellow-500 text-xl" />,
      value: selectedRow?.ASNNUM || "",
      bg: "bg-white",
      iconbg: "bg-yellow-100",
    },
    {
      label: "Truck Number",
      icon: <FaClipboardCheck className="text-pink-500 text-xl" />,
      value: selectedRow?.TRUCKNUM || "",
      bg: "bg-white",
      iconbg: "bg-pink-100",
    },
    {
      label: "Vendor",
      icon: <FaClipboardCheck className="text-orange-500 text-xl" />,
      value: selectedRow?.VENDOR || "",
      bg: "bg-white",
      iconbg: "bg-orange-100",
    },
    {
      label: "STI Id",
      icon: <FaClipboardCheck className="text-blue-500 text-xl" />,
      value: selectedRow?.STIID || "",
      bg: "bg-white",
      iconbg: "bg-blue-100",
    },
  ];

  const itemToRender = isType === "inbound" ? inboundItem : outboundItem;

  return (
    <>
      <div className="overflow-y-auto">
        <p className="pb-2 pt-2 pl-4 cus-h2-heading border-b">
          {"Order Details"}
        </p>
        <div className="p-1 flex flex-wrap border-b">
          {itemToRender.map((item, index) => (
            <div className="p-1 pl-3 w-1/3 flex flex-col" key={index}>
              <p>
                <span className="visdtl-cus-heading">{item.label}</span>:{" "}
                <span className="visdtl-cus-value">{item.value}</span>
              </p>
            </div>
          ))}
        </div>

        <div className="pt-2 pl-4 w-full border-b">
          <p className="cus-h2-heading">{"Order Tracking"}</p>
          <div className="flex items-center justify-center pt-5 mr-24">
            {visibilityStatusList.map((item, index) => (
              <React.Fragment key={index}>
                {/* timeline-iconstarting */}
                <div className="flex flex-col">
                  <div
                    className={`flex items-center justify-center w-10 h-10 rounded-full ${
                      item.completed ? "bg-[#008000]" : "bg-gray-300"
                    } text-white`}
                  >
                    {React.cloneElement(
                      stepIcon[item.step] || stepIcon["default"],
                      {
                        style: {
                          color: item.completed ? "white" : "white",
                        },
                      }
                    )}
                  </div>
                </div>

                {index < visibilityStatusList.length - 1 && (
                  <div
                    className={`flex-grow border-t-2 ${
                      item.completed ? "border-[#008000]" : "border-gray-300"
                    } `}
                  ></div>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 mb-2">
            {visibilityStatusList.map((item, index) => (
              <div
                key={index}
                className="mt-1 text-sm"
                style={{ marginLeft: index === 0 ? "0" : `${index * 10}%` }}
              >
                <span className="visdtl-cus-timelineheading block">
                  {item.step || ""}
                </span>
                <span className="visdtl-cus-timelinevalue block">
                  {"Date : "}
                  {item.date || ""}
                </span>
                {item.step !== "Order Placed" &&
                  item.step !== "truckCheckIn" && (
                    <span className="visdtl-cus-timelinevalue block">
                      {"ETA : "}
                      {item.expectedDate || "N/A"}
                    </span>
                  )}
              </div>
            ))}
          </div>
        </div>

        <p className="cus-h2-heading pt-2 px-4">{"History Logs"}</p>
        <div className="ml-4 mr-1 bg-gray-100 pl-1 mt-2">
          <div className="flex w-full p-1">
            <div className="com-heading w-1/4">{"Time and Date"}</div>
            <div className="com-heading w-3/4">{"Actions"}</div>
          </div>
        </div>
        <div className="ml-4 mr-1 bg-gray-50 pl-1 overflow-y-auto">
          {visibilityDetailList.map((item) => (
            <div key={item.id} className="flex w-full p-0.5">
              <div className="cus-his-value w-1/4">{item.DTCR}</div>
              <div className="cus-his-value w-3/4">{item.REMARK}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default VisibilityDetailpage;
